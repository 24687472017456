import { Box, Typography, Card, Grid, TextField, Button, TablePagination, Pagination } from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { get_order_notification, update_order_notification_status } from '../../Services/APIService';
import axios from 'axios';

export default function Notifications() {

  const [notifications , setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = notifications.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(notifications.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleClick = (id) =>{
    const sendData = new FormData();
    sendData.append('notificationId', id);
    axios({
        method: "POST",
        url: update_order_notification_status,
        data: sendData
    }).then(res => {
        if (!res.data.error) {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            getNotifications()
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        }
    }).catch(err => {
        console.log(err)
    });
  }

  const getNotifications = () =>{
    axios({
        method: "GET",
        url: get_order_notification,
    }).then(res => {
        if (res.data.error) {
          setNotifications([])
        } else {
          setNotifications(res.data.data);
        }
    }).catch(err => {
        console.log(err)
    });
  }

useEffect(()=>{
  getNotifications();
}, []);


  
   
  return (
    <Box p={2}>
    <Box p={2}>

     <Box py={3}>
       <Heading  title={'Notifications'}/>
     </Box>

     {
     currentItems.map((i) => (
     <Box component={Card} mb={2} sx={{cursor:'pointer'}}  bgcolor={i.isRead === 1 ? '#ffff' : '#8dd8e5'} onClick={()=>handleClick(i.notificationId)}>
       <Grid container>
         <Grid item xs={12} sm={12} md={12} lg={12} >
          <Box p={1}>
          <Box display='flex' justifyContent='space-between'>
          <Box textAlign='left' p={1}>
         <Typography variant='subtitle2' fontWeight={550}>{i.messages}</Typography>
         </Box>
         <Box  display='flex' justifyContent='end' alignContent='end' alignItems='end'>
         <Typography variant='caption' fontWeight={550}>{i.createdAt}</Typography>
         </Box>
          </Box>
          </Box>
         </Grid>
       </Grid>
     </Box>
  ))}

    <Box mt={4} display='flex' justifyContent='center'>
    <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
    </Box>

     </Box>
     </Box>
  )
}
