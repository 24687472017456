import { Box, Grid, Card ,Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';
import { TextField } from "@mui/material";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { get_balance_sheet, get_income_statement } from '../../../Services/APIService';
import { ExportExcel } from '../ExportExcel';

export default function IncomeStatements() {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);


  const getallIncomeSheet = () =>{
    const sendData = new FormData()
    sendData.append('IncomeSheetPeriod' , '')
    axios({
        method: "POST",
        url: get_income_statement,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
             setData(res.data.data);
            setStatus(true)
            setColor(true)
        }
    }).catch(err => {
        console.log(err)
    });
    }

        useEffect(() => {
          getallIncomeSheet();
        }, [])

  return (
    <Box p={2}>

<Box display='flex' flexDirection='row' justifyContent='end' py={1}>
    <ExportExcel data={data} />
    </Box>

<TableContainer component={Paper} sx={{ boxShadow: 4 }}>
  <Table id='cashTable'>
    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
      <TableRow sx={{ '& > *': { width: '2%' } }}>
        <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }}>
          Items
        </TableCell>
        {data &&
          Object.keys(data).map((month) => (
            <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }} key={month}>
              {month}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>

    <TableBody>
      {data &&
        Object.keys(data[Object.keys(data)[0]] || {})
          .filter((item) => item !== 'incomeId' && item !== 'incomePeriod') // Exclude specific keys
          .map((item) => (
            <TableRow key={item}>
          <TableCell align='left' sx={{ fontWeight: 600 }}>
          {item.split(/(?=[A-Z])/).join(' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
          </TableCell>
              {data &&
                Object.keys(data).map((month) => (
                  <TableCell align='center' sx={{ fontWeight: 500, fontSize: 14 }} key={month}>
                    {data[month] && data[month][item] !== undefined && data[month][item] !== null
                      ? typeof data[month][item] === 'number'
                        ? Number(data[month][item]).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
                        : data[month][item]
                      : ''}
                  </TableCell>
                ))}
            </TableRow>
          ))}
    </TableBody>
  </Table>
</TableContainer>
    </Box>
  )
}
