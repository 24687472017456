import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function ReportDialog({
  setOpenDialog, getAllExpense, getAllWebOrders , 
  fromDate , toDate , setFromDate , setToDate , 
  setMessage, setOpen, setStatus, setColor,setSelectedReport,
  openDialog , selectedReport,getAllprofitlossreport,
  getAllPurchase,getAllSupplier, getAllEmployee , getAllStock,getAllProducts, getAllChannelOrders,getAllWholeSaleOrders,
}) {

    const handleClose = () => {
      setOpenDialog(false);
      setSelectedReport('');
    };

    const GenerateReport = async () => {
      if ((fromDate === '' && toDate === '') || (fromDate !== '' && toDate !== '')) {
          switch (selectedReport) {
              case 'Expense':
                  getAllExpense();
                  break;
              case 'Web Orders':
                  getAllWebOrders();
                  break;
              case 'Channel Sale':
                  getAllChannelOrders();
                  break;
                  case 'Whole Sale':
                    getAllWholeSaleOrders();
                  break;
              case 'Product':
                  getAllProducts();
                  break;
              case 'Stock':
                  getAllStock();
                  break;
              case 'Employee':
                  getAllEmployee();
                  break;
              case 'People':
                  getAllSupplier();
                  break;
              case 'Purchase':
                  getAllPurchase();
                  break;
              case 'Profit':
                  getAllprofitlossreport();
                    break;
              default:
                  // Handle other cases if needed.
                  break;
          }
      } else {
        setMessage('Fill both Fields')
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
  };

  


  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='sm'>
    <DialogTitle>
    <Box p={1} borderBottom='1px solid rgba(0,0,0,.1)'>
    {selectedReport} Report
    </Box>
   </DialogTitle>
    <DialogContent>
    <Stack textAlign='left'>
    <Grid container justifyContent='center'>
     <Grid item xs={12} lg={2} justifyContent='start' display='flex'>
     <Stack spacing={4} textAlign='left'>
     <Typography py={1}>From</Typography>
     <Typography py={1}>To</Typography>
     </Stack>
     </Grid>
     <Grid item xs={12} lg={8}  justifyContent='start'>
     <Stack spacing={4} textAlign='left'>
     <TextField
     type="date"
     id="start" 
     size='small'
     name="trip-start"
    fullWidth
     onChange={(e) => {
     setFromDate(e.target.value);
     }}
     required
     InputLabelProps={{
       shrink: true,
     }}
       />

       <TextField
       type="date"
       id="end" 
       size='small'
       name="trip-end"
       fullWidth
       onChange={(e) => {
       setToDate(e.target.value);
       }}
       required
       InputLabelProps={{
         shrink: true,
       }}
         />
     </Stack>
     </Grid>
    </Grid>
   </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='error' variant='contained'>Cancel</Button>
      <Button
      variant="contained"
      color='warning'
      onClick={GenerateReport}
      endIcon={<ExitToAppIcon />}
    >
    Generate Report
    </Button>
    </DialogActions>
  </Dialog>
  )
}
