import { Box, Typography, Card, Grid, TextField, Button, Autocomplete, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useState } from 'react';
import { CategoryImageAPI, add_main_category, getallcategory} from '../../Services/APIService';
import axios from 'axios';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AllCategoryTable } from '../../Variables/Variables';

export default function NewCategory() {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [newCategory, setNewCategory] = useState('');
    const [newCategoryImage, setNewCategoryImage] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [categoryList, setCategoryList] = useState([]);

    const getallcategoryAPI = () =>{
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
               
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
        }

            useEffect(() => {
              getallcategoryAPI();
            }, [])



    const handleClick = () => {
        const serverData = new FormData()
        serverData.append('mainCategoryName', newCategory)
        serverData.append('categoryImage', newCategoryImage)
        axios({
            method: "POST",
            url: add_main_category,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setNewCategory('');
                document.getElementById('categoryImage').value = '';
                getallcategoryAPI();
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
  return (
      <Box p={3}>

          <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
          <Box py={3}>
              <Heading title={'Create Category'} />
          </Box>

          <Box component={Card} py={3} boxShadow={4}>
              <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Box textAlign='left' p={2}>
                          <Typography variant='h6' fontWeight={500}>New Category</Typography>
                      </Box>
                      <Grid container display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} p={2} gap={3}>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                              <TextField
                                  required
                                  onChange={(e) => setNewCategory(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Category Name"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                              <TextField
                                  required
                                  fullWidth
                                  id="categoryImage"
                                  label="Category Image"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  type="file"
                                  onChange={(e) => setNewCategoryImage(e.target.files[0])}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                              />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                              <Box>
                                  <Button onClick={handleClick} variant='contained'
                                      sx={{ width: '25ch', bgcolor: '#faa634', ':hover': { bgcolor: '#316C31' } }}>
                                      Create Category</Button>
                              </Box>
                          </Grid>
                      </Grid>

                  </Grid>
              </Grid>
          </Box>

          <Box py={2} mt={3}>
              <Heading title={'All Categories'} />
          </Box>

          <Box py={3}>
              <Grid container>
                  <TableContainer sx={{ border: '1px solid silver', boxShadow:4 }} >

                      <Table>
                          <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                              <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                  {
                                      AllCategoryTable.map((i, index) => {
                                          return (
                                              <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                      <Box sx={{ my: 'auto' }}>
                                                          {i.title}
                                                      </Box>
                                                  </Box>
                                              </TableCell>
                                          )
                                      })
                                  }
                              </TableRow>
                          </TableHead>


                          <TableBody>

                              {
                                  categoryList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                      return (
                                          <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.categoryId}</TableCell>
                                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}> <img style={{ width: 80, height: 80 }} src={`${CategoryImageAPI}${i.categoryImage}`} alt='Image' /> </TableCell>
                                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.categoriesName}</TableCell>
                                          </TableRow>
                                      )
                                  })
                              }

                          </TableBody>
                      </Table>
                  </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={categoryList.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </Grid>
          </Box>
    </Box>
  )
}
