import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { Container, Grid, Button, Card, TextField, Stack } from '@mui/material';
import {  add_expense_sub_category, get_allproducts, get_expense_category, update_expense_category, update_expense_sub_category,} from '../../Services/APIService';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpenseCategoryDelete from './ExpenseCategoryDelete';
import Heading from '../../Components/Heading/Heading';
import ExpenseCategroyDialog from './ExpenseCategroyDialog';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row  , i , getAllExpense , setMessage, setOpen, setStatus, setColor} = props;
  const [openAccord, setOpenAccord] = React.useState(false);

  const [newSubCategory , setnewSubCategory] = useState('');
  const [newSubCategory1 , setnewSubCategory1] = useState('');
  const [newCategory , setnewCategory] = useState('');
  const [editMode , setEditMode] = useState(false);
  const [editId , setEditId] = useState('');
  const [SelectedDelete , setSelectedDelete] = useState('');

  const [openDialog , setOpenDialog] = useState(false);
  const [dialogDataName , setDialogDataName] = useState('')
  const [dialogDataId , setDialogDataId] = useState('')

  const handleAddSubCategory = (id) => {
    if (newSubCategory !== '' && newSubCategory !== null) {
        const sendData = new FormData();
        sendData.append('expenseCategoryId', id);
        sendData.append('expenseSubCategory', newSubCategory);
        axios({
            method: "POST",
            url:add_expense_sub_category,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                getAllExpense();
                setnewSubCategory('');
            }
        }).catch(err => {
            console.log(err)
        });
    }
  }


  const handleUpdateSubCategory = (catId , subId , name) => {

    if (newSubCategory1 !== '' && newSubCategory1 !== null) {
        const sendData = new FormData();
        sendData.append('expenseCategoryId', catId);
        sendData.append('expenseSubCategory', newSubCategory1);
        sendData.append('expenseSubCategoryId', subId);
        axios({
            method: "POST",
            url:update_expense_sub_category,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                getAllExpense();
                setnewSubCategory('');
                setEditId('');
                setEditMode(false);
                setnewSubCategory1('');
            }
        }).catch(err => {
            console.log(err)
        });
    }
  }

  const handleEdit = ( subId , name) =>{
    setEditId(subId);
    setEditMode(true);
    setnewSubCategory1(name);
  }

  
  const handleCatEdit = ( subId , name) =>{
    setEditId(subId);
    setEditMode(true);
    setnewCategory(name);
  }
 
  const Cancel = () =>{
    setEditId('');
    setEditMode(false);
    setnewSubCategory('');
    setnewCategory('');
  }

  const handleOpenDialog = (item , id ,  name) =>{
    setSelectedDelete(item);
    setOpenDialog(true);
    setDialogDataId(id);
    setDialogDataName(name);
  }

  const handleUpdateExpenseCategory = (catId) => {
    if (catId !== '' && catId !== null) {
    const sendData = new FormData();
    sendData.append('expenseCategory', newCategory);
    sendData.append('expenseCategoryId', catId);
    axios({
    method: "POST",
    url: update_expense_category,
    data: sendData
    }).then(res => {
    if (res.data.error) {
    setMessage(res.data.message)
    setOpen(true)
    setStatus(false)
    setColor(false)
    } else {
    setOpen(true)
    setMessage(res.data.message)
    setStatus(true)
    setColor(true)
    getAllExpense();
    setEditId('');
    setEditMode(false);
    setnewCategory('');
    setnewSubCategory1('');
    }
    }).catch(err => {
    console.log(err)
    });
    }
}


  return (
    <React.Fragment>
        <ExpenseCategoryDelete setOpenDialog={setOpenDialog} openDialog={openDialog} setSelectedDelete={setSelectedDelete} setDialogDataName={setDialogDataName} dialogDataName={dialogDataName} setDialogDataId={setDialogDataId} dialogDataId={dialogDataId} SelectedDelete={SelectedDelete} getAllExpense={getAllExpense} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{i + 1}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenAccord(!openAccord)}
          >
            {openAccord ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {
          ( editId === row.expenseCategoryId && editMode ) ?
          <Box display='flex' flexDirection='row' gap={2}>
          <TextField 
          id="outlined-basic"
          size='small'
          fullWidth
          value={newCategory}
          onChange={(e) => setnewCategory(e.target.value)}
          variant="outlined"
          />
          <HighlightOffIcon onClick={Cancel} sx={{color:'red' , verticalAlign:'middle' , fontSize:'30px', cursor:'pointer'}} />
          <CheckCircleIcon  onClick={()=>handleUpdateExpenseCategory(row.expenseCategoryId)}  sx={{color:'green' , verticalAlign:'middle' , fontSize:'30px', cursor:'pointer'}} />
          </Box>
          :
          <Typography>{row.expenseCategory}</Typography> 
          }
        </TableCell>
        <TableCell align="center">{row.totalAmount}</TableCell>
        <TableCell align="center">                              
        <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
        <Button onClick={()=>handleCatEdit(row.expenseCategoryId , row.expenseCategory )} variant="contained" sx={{ height: 25, width: 80, fontWeight: 600, opacity: 0.9 , fontSize:12}} ><DriveFileRenameOutlineIcon sx={{verticalAlign:'middle', mr:1}} /> EDIT</Button>
        <Button onClick={()=>handleOpenDialog('CATEGORY' ,  row.expenseCategoryId , row.expenseCategory )} variant="contained" sx={{ height: 25, width: 90, fontWeight: 600, opacity: 0.9, fontSize: 12 }} color='error'><DeleteForeverIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> DELETE</Button>
        </Box>
        </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0 , paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAccord} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack spacing={1} p={2} mb={4}>
               <Typography>New Subcategory</Typography>
               <Grid container spacing={3}>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                id="outlined-basic"
                size='small'
                fullWidth
                value={newSubCategory}
                onChange={(e) => setnewSubCategory(e.target.value)}
                variant="outlined"
                />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                <Button onClick={()=>handleAddSubCategory(row.expenseCategoryId)} variant="contained" sx={{ fontWeight: 600, opacity: 0.9 , fontSize:12 , py:1 , bgcolor:'green', color:'#fff'}} >CREATE</Button>
                </Grid>
               </Grid>
              </Stack>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>SubCategory</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subCategory?.map((historyRow) => (
                    <TableRow key={historyRow.expenseSubCategoryId}>
                      <TableCell component="th" scope="row">

                      {
                        ( editId === historyRow.expenseSubCategoryId && editMode ) ?
                        <Box display='flex' flexDirection='row' gap={2}>
                        <TextField 
                        id="outlined-basic"
                        size='small'
                        fullWidth
                        value={newSubCategory1}
                        onChange={(e) => setnewSubCategory1(e.target.value)}
                        variant="outlined"
                        />
                        <HighlightOffIcon onClick={Cancel} sx={{color:'red' , verticalAlign:'middle' , fontSize:'30px', cursor:'pointer'}} />
                        <CheckCircleIcon  onClick={()=>handleUpdateSubCategory(row.expenseCategoryId , historyRow.expenseSubCategoryId , historyRow.expenseSubCategory)}  sx={{color:'green' , verticalAlign:'middle' , fontSize:'30px', cursor:'pointer'}} />
                        </Box>
                        :
                        <Typography>{historyRow.expenseSubCategory}</Typography> 
                      }
                        
                      </TableCell>
                      <TableCell align="left">{historyRow.expenseAmount}</TableCell>
                      <TableCell align="left">
                      <Button onClick={()=>handleEdit(historyRow.expenseSubCategoryId , historyRow.expenseSubCategory)}  variant="contained" sx={{ height: 25, width: 80, fontWeight: 600, opacity: 0.9 , fontSize:12}} ><DriveFileRenameOutlineIcon sx={{verticalAlign:'middle', mr:1}} /> EDIT</Button>
                      </TableCell>
                      <TableCell align="left">
                      <Button onClick={()=>handleOpenDialog('SUB-CATEGORY' ,  historyRow.expenseSubCategoryId , historyRow.expenseSubCategory )} variant="contained" sx={{ height: 25, width: 90, fontWeight: 600, opacity: 0.9, fontSize: 12 }} color='error'><DeleteForeverIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> DELETE</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




export default function NewExpenseCategoryTable() {


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [ExpenseCategory, setExpenseCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [openExpenseDialog , setOpenExpenseDialog] = useState('');
    const [openExpenseDeleteDialog, setOpenExpenseDeleteDialog] = useState('');
    const [ExpenseCategoryId, setExpenseCategoryId] = useState('');
    const [ExpenseCategoryName, setExpenseCategoryName] = useState('');
    const[yearnumber , setYearNumber] = useState('');
    const textFieldYearRef = useRef();


    const [showMonth , setShowMonth ] = useState(false);
    const [showYear , setShowYear ] = useState(true);
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const[currentMode , setCurentMode] = useState('Yearly');
    const textFieldRef = useRef();
    const textFieldFromRef = useRef();


    const getAllExpense  = () =>{
        const sendData = new FormData();
        axios({
          method: "POST",
          url: get_expense_category,
        
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });
      }
     
       useEffect(() => {
         getAllExpense();
       }, []);

       const getFilteredYearData = (year) =>{
        const sendData = new FormData();
        sendData.append('expenseYear', year !== undefined ? year : '')
        axios({
          method: "POST",
          url: get_expense_category,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
       }


       
       const getFilteredMonthData = (year) =>{
        if( textFieldRef?.current?.value !== undefined &&  textFieldRef?.current?.value !== '' &&  textFieldRef?.current?.value !== null){
        const sendData = new FormData();
        sendData.append('checkBox', 'Monthly');
        sendData.append('fromDate', textFieldFromRef?.current?.value !== undefined ? textFieldFromRef?.current?.value : '');
        sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : '');
        axios({
          method: "POST",
          url: get_expense_category,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
      }
       }

       const ChangeMode = (mode) =>{
        setCurentMode(mode);
        if (mode === 'Yearly'){
          setCurentMode('Yearly');
         setShowYear(true);
        setShowMonth(false);
        getFilteredYearData()
        }
        else if (mode === 'Monthly'){
          setCurentMode('Monthly')
          setShowMonth(true);
          setShowYear(false);
          getFilteredMonthData();
        }
      }



       const handleOpenAddDialog = () => {
        setOpenExpenseDialog(true);
         }


  return (
    <Box p={3}>
    
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <ExpenseCategroyDialog setExpenseCategoryId={setExpenseCategoryId} getAllExpense={getAllExpense} ExpenseCategoryId={ExpenseCategoryId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseDialog={openExpenseDialog} setOpenExpenseDialog={setOpenExpenseDialog} />
      <Box p={3}>
      <Heading title={'Expense Category'} />
      </Box>

        <Box mb={3} mt={1}>
        <Grid container>
        <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
        <Button variant='contained' color='success' onClick={handleOpenAddDialog}
        sx={{ borderRadius: 0, boxShadow: 0, fontWeight: 600 }} ><AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }} /> NEW EXPENSE CATEGORY RECORD</Button>

        </Box>
        </Grid>
        <Grid item xs={12} justifyContent='end' display='flex'>
      <Grid container spacing={3}>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box sx={{cursor:'pointer'}} display='flex' flexDirection='row' gap={3}>
       <Typography sx={{color: currentMode === 'Monthly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Monthly')}>Monthly</Typography>
       <Typography sx={{color: currentMode === 'Yearly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Yearly')}>Yearly</Typography>
    </Box>
      </Grid>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box>
      {
        showMonth &&
        <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'row', xs:'column'}} gap={2}>
        <TextField
        type="date"
        id="start" 
        size='small'
        name="trip-start"
        inputRef={textFieldFromRef}
        onChange={(e) => {
        setFromDate(e.target.value);
        getFilteredMonthData();
        }}
        label='From date'
        required
        InputLabelProps={{
          shrink: true,
        }}
          />
    
          <TextField
          type="date"
          id="end" 
          size='small'
          name="trip-end"
          inputRef={textFieldRef}
          onChange={(e) => {
          setToDate(e.target.value);
            getFilteredMonthData();
          }}
          label='To date'
          required
          InputLabelProps={{
            shrink: true,
          }}
            />
       </Box>
      }
      {
        showYear &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        views={['year']}
        label="Year"
        openTo='year'
        slotProps={{ textField: { size: 'small' } }}
        inputRef={textFieldYearRef}
        dateFormat="yyyy"
        onChange={(newValue) => {
        const date = new Date(newValue);
        const YearDate = date.getFullYear();
        setYearNumber(YearDate);
        getFilteredYearData(YearDate);
        }}
        renderInput={(params) => <TextField 
        {...params} 
        helperText={null}
        inputProps={{
        ...params.inputProps,
        readOnly: true
        }} size='small' />}
        />
        </LocalizationProvider>
      }
      </Box>
      </Grid>
      </Grid>
      </Grid>
        </Grid>
        </Box>


    <TableContainer component={Card} sx={{boxShadow:6}}>
      <Table aria-label="collapsible table">
        <TableHead  sx={{ whiteSpace: 'nowrap' , bgcolor:'#616e80' }}>
          <TableRow>
          <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>#</TableCell>
          <TableCell />
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>Expense Category</TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }} align="center">Amount</TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }} align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ExpenseCategory?.map((row , index) => (
            <Row key={row.name} row={row} i={index} getAllExpense={getAllExpense} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}
