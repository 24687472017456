import React from 'react'
import { useRoutes, Navigate } from 'react-router-dom'

// this is pages
import Pagenotfound from './Pages/PageNotFound/Pagenotfound';
import AdminLoginPage from './Pages/LoginPage/AdminLoginPage';
import DashBoardLayout from './Pages/AdminDashBrdLayout/DashBoardLayout';


import DashBoard from './Pages/Dashbrd/DashBoard';
import Orders from './Pages/Orders/Orders';
import Notifications from './Pages/Notification/Notifications';
import Access from './Pages/Access/Access';
import Users from './Pages/People/Users';
import ProductsPage from './Pages/Products/ProductsPage';
import AuthPersonList from './Pages/Access/AuthPersonList';
import AllProductsList from './Pages/Products/AllProductsList';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import UpdateProducts from './Pages/Products/UpdateProducts';
import ArchiveProducts from './Pages/Products/ArchiveProducts';
import PurchaseList from './Pages/Purchase/PurchaseList';
import CreateNewPurchase from './Pages/Purchase/CreateNewPurchase';
import SalesList from './Pages/Sales/SalesList';
import CreateNewSale from './Pages/Sales/CreateNewSale';
import ReturnSale from './Pages/ReturnAndRefunds/ReturnSale';
import NewCategory from './Pages/Category/NewCategory';
import UpdateCategory from './Pages/Category/UpdateCategory';
import ExpensesList from './Pages/Expenses/ExpensesList';
import ExpenseCategory from './Pages/Expenses/ExpenseCategory';
import PurchaseDetails from './Pages/Purchase/PurchaseDetails';
import Vendors from './Pages/People/Vendors';
import OrdersDetailedview from './Pages/Orders/OrdersDetailedview';
import UpdateProductTable from './Pages/Products/UpdateProductTable';
import ReturnOrderTab from './Pages/ReturnAndRefunds/ReturnOrderTab';
import WebsiteTab from './Pages/WebsiteUI/HomePageTab';
import SaleTab from './Pages/WebsiteUI/SaleTab';
import InquiryTab from './Pages/People/InquiryTab';
import ConfirmedOrder from './Pages/Orders/ConfirmedOrder';
import CancelledOrder from './Pages/Orders/CancelledOrder';
import ViewUpdatedOrderDetail from './Pages/Orders/ViewUpdatedOrderDetail';
import ViewCancelledDetails from './Pages/Orders/ViewCancelledDetails';
import TredingTab from './Pages/WebsiteUI/Trending/TrendingTab';
import BulkProductTable from './Pages/WebsiteUI/BrandsProducts/BulkProductTable';
import SaleDetails from './Pages/Sales/SaleDetails';
import EditAccess from './Pages/Access/EditAccess';
import ReturnDetailView from './Pages/ReturnAndRefunds/ReturnDetailView';
import ReportTab from './Pages/Report/ReportTab';
import WholeSaleList from './Pages/WholeSale/WholeSaleList';
import NewWholeSale from './Pages/WholeSale/NewWholeSale';
import WholeSaleDetails from './Pages/WholeSale/WholeSaleDetail';
import NewExpenseCategoryTable from './Pages/Expenses/NewExpenseCategoryTable';
import NewExpenseList from './Pages/Expenses/NewExpenseList';
import BankAccountTab from './Pages/Account/BankAccount/BankAcountTab';
import IncomeSheetTab from './Pages/Account/IncomeSheet/IncomeSheetTab';
import CashFlowTab from './Pages/Account/CashFlow/CashFlowTab';
import BalanceSheetTab from './Pages/Account/BalanceSheet/BalanceSheetTab';
//this variable is for local development 
export const startUrl = `/`;



function Routes() {

    const Adminauth = JSON.parse(localStorage.getItem('Adminauth'));


    return useRoutes([
        {
            path: `*`,
            element: <Pagenotfound />
        }, 
        { path: startUrl, 
         element: <AdminLoginPage />
        },
        { path: `${startUrl}resetpassword`, element:<ForgotPassword />  },
        {  path: `${startUrl}dashbrdlayout`,
        element: Adminauth ? <DashBoardLayout /> : <Navigate to={startUrl} />,
         children : [
            { path: `logout`, element: <Navigate to={startUrl} /> },
            { path: `orders`, element:<Orders />  },
             { path: `newcategory`, element: <NewCategory /> },
             { path: `editcategory`, element: <UpdateCategory /> },
             { path: `expenses`, element: <NewExpenseList /> },
             { path: `expensescategory`, element: <NewExpenseCategoryTable /> },
            { path: `dashboard`, element:<DashBoard />  },
            { path: `notifications`, element:<Notifications />  },
            { path: `access`, element:<Access />  },
            { path: `users`, element:<Users />  },
            { path: `authpersonlist`, element:<AuthPersonList />  },
            { path: `productspage`, element:<ProductsPage />  },
            { path: `allproductspage`, element:<AllProductsList />  },
            { path: `archive`, element:<ArchiveProducts />  },
            { path: `editproductspage`, element: <UpdateProductTable /> },
             {path: `updatesection`, element: <UpdateProducts /> },
             { path: `purchaselist`, element: <PurchaseList /> },
             { path: `purchasedetails`, element: <PurchaseDetails /> },
             { path: `saledetails`, element: <SaleDetails /> },
             { path: `wholesaledetails`, element: <WholeSaleDetails /> },
             { path: `ordersdetails`, element: <OrdersDetailedview /> },
             { path: `retrundetailview`, element: <ReturnDetailView /> },
             { path: `createnewpurchase`, element: <CreateNewPurchase /> },
             { path: `saleslist`, element: <SalesList /> },
             { path: `createnewsale`, element: <CreateNewSale /> },
             { path: `returnsale`, element: <ReturnOrderTab /> },
             { path: `vendor`, element: <Vendors /> },
             { path: `hompage`, element: <WebsiteTab /> },
             { path: `trending`, element: <TredingTab /> },
             { path: `sale`, element: <SaleTab /> },
             { path: `bulk`, element: <BulkProductTable /> },
             { path: `reports`, element: <ReportTab /> },
             { path: `editaccess`, element: <EditAccess /> },
             { path: `inquiry`, element: <InquiryTab /> },
             { path: `confirmed`, element: <ConfirmedOrder /> },
             { path: `cancelled`, element: <CancelledOrder /> },
             { path: `account`, element: <BankAccountTab /> },
             { path: `incomesheet`, element: <IncomeSheetTab /> },
             { path: `cashflow`, element: <CashFlowTab /> },
             { path: `balancesheet`, element: <BalanceSheetTab /> },
             { path: `wholesalelist`, element: <WholeSaleList /> },
             { path: `createnewwholesale`, element: <NewWholeSale /> },
             { path: `updatedorderDetails`, element: <ViewUpdatedOrderDetail /> },
             { path: `cancelledorderDetails`, element: <ViewCancelledDetails /> },
         ]
    },
    ])
}

export default Routes