import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    IconButton,
    Box, Container, Stack, Typography, Button , Autocomplete
} from "@mui/material";
import axios from "axios";
import { update_wholesale_trendz_banner } from "../../../Services/APIService";
import SnackBar from "../../../Components/AdminDashBoardComps/Snackbar";

export default function BannerText({screen, id ,getAllData, bannerCaption, bannerHeading, setBannerCaption, setBannerHeading}) {


    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");


    const handleClick = () => {
        const serverData = new FormData()
        serverData.append("bannerId", id)
        serverData.append("screenName", screen)
        serverData.append("bannerHeading", bannerHeading)
        serverData.append("bannerCaption", bannerCaption)
        axios({
            method: "POST",
            url: update_wholesale_trendz_banner,
            data: serverData,
        }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              getAllData();
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
        }).catch(err => {
            console.log(err)
        });
    }


  return (
    <Container>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
    <Stack py={3} spacing={2}>
    <TextField
    label="Banner Heading"
    fullWidth
    variant="outlined"
    value={bannerHeading}
    onChange={(e) =>setBannerHeading(e.target.value)}
    />
    <TextField
    label="Banner Caption"
    fullWidth
    variant="outlined"
    value={bannerCaption}
    onChange={(e) =>setBannerCaption(e.target.value)}
    />
     <Button variant="contained" onClick={handleClick}>Update</Button>
    </Stack>

    </Container>
  )
}
