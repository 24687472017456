import React from 'react'
import { Box, Typography, Container, Grid, Rating, Card, CardMedia, Link, CardContent,  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Breadcrumbs, CardActionArea, Dialog, DialogContent,DialogActions, DialogTitle, DialogContentText, Stack, TextField, List, Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton, InputAdornment, Input, Divider } from '@mui/material'
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AddBox, EmailOutlined } from '@mui/icons-material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { add_cash_flow, get_expense_sale_for_cash_flow } from '../../../Services/APIService';
import CustomTextField from '../../../Components/CustomTextField';
import SnackBar from '../../../Components/AdminDashBoardComps/Snackbar';


export default function CashFlowForm() {


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [cashFlowPeriod , setcashFlowPeriod] = useState(null);
  const [startingCashBalance, setStartingCashBalance] = useState('');
  const [onlineSales, setonlineSales] = useState('');
  const [channelSales, setchannelSales] = useState('');
  const [wholeSales, setwholeSales] = useState('');
  const [cashSubTotal, setcashSubTotal] = useState('');
  const [newCurrentBorrowing, setNewCurrentBorrowing] = useState('');
  const [newLongTermLiabilities, setNewLongTermLiabilities] = useState('');
  const [salesOfCurrentAssets, setSalesOfCurrentAssets] = useState('');
  const [salesOfLongTermAssets, setSalesOfLongTermAssets] = useState('');
  const [newInvestmentReceived, setNewInvestmentReceived] = useState('');
  const [subTotalCashReceived, setSubTotalCashReceived] = useState('');
  const [totalCashReceived, setTotalCashReceived] = useState('');
  const [cashSpendingExpense, setCashSpendingExpense] = useState('');
  const [billPaymentExpense, setBillPaymentExpense] = useState('');
  const [expenseSubTotal, setExpenseSubTotal] = useState('');

  const [repaymentOfCurrentBorrowing, setRepaymentOfCurrentBorrowing] = useState('');
  const [longTermLiabilitiesRepayment, setLongTermLiabilitiesRepayment] = useState('');
  const [purchaseOfCurrentAssets, setPurchaseOfCurrentAssets] = useState('');
  const [purchaseOfLongTermAssets, setPurchaseOfLongTermAssets] = useState('');
  const [dividends, setDividends] = useState('');
  const [subTotalCashSpent, setSubTotalCashSpent] = useState('');
  const [totalCashSpent, setTotalCashSpent] = useState('');
  const [netCashFlow, setNetCashFlow] = useState('');
  const [endingCashBalance, setEndingCashBalance] = useState('');

  const getallData = () =>{
    if(cashFlowPeriod !== null && cashFlowPeriod !== '' && cashFlowPeriod !== undefined){
  const originalDate = new Date( cashFlowPeriod);
  const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;
    const sendData = new FormData()
    sendData.append('cashFlowPeriod' , formattedDate)
    axios({
        method: "POST",
        url: get_expense_sale_for_cash_flow,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStartingCashBalance(res.data.data.startingCashBalance)
            setCashSpendingExpense(res.data.data.cashSpendingExpense)
            setBillPaymentExpense(res.data.data.billPaymentExpense)
            setExpenseSubTotal(res.data.data.expenseSubTotal)
            setonlineSales(res.data.data.onlineSales)
            setchannelSales(res.data.data.channelSales)
            setwholeSales(res.data.data.wholeSales)
            setcashSubTotal(res.data.data.salesSubTotal)
            setStatus(true)
            setColor(true)
        }
    }).catch(err => {
        console.log(err)
    });
  }
    }

    useEffect(()=>{
      getallData();
    },[cashFlowPeriod])



      useEffect(() => {
        const parsedNewCurrentBorrowing = parseFloat(newCurrentBorrowing) || 0;
        const parsedNewLongTermLiabilities = parseFloat(newLongTermLiabilities) || 0;
        const parsedSalesOfCurrentAssets = parseFloat(salesOfCurrentAssets) || 0;
        const parsedSalesOfLongTermAssets = parseFloat(salesOfLongTermAssets) || 0;
        const parsedNewInvestmentReceived = parseFloat(newInvestmentReceived) || 0;
      
        const calculatedSubTotalCashReceived =
          parsedNewCurrentBorrowing +
          parsedNewLongTermLiabilities +
          parsedSalesOfCurrentAssets +
          parsedSalesOfLongTermAssets +
          parsedNewInvestmentReceived;
      
        setSubTotalCashReceived(calculatedSubTotalCashReceived);
        setTotalCashReceived(calculatedSubTotalCashReceived + (data?.salesSubTotal || 0));
      }, [newCurrentBorrowing, newLongTermLiabilities, salesOfCurrentAssets, salesOfLongTermAssets, newInvestmentReceived, data?.salesSubTotal]);

      useEffect(() => {
        const parsedrepaymentOfCurrentBorrowing = parseFloat(repaymentOfCurrentBorrowing) || 0;
        const parsedlongTermLiabilitiesRepayment = parseFloat(longTermLiabilitiesRepayment) || 0;
        const parsedpurchaseOfCurrentAssets = parseFloat(purchaseOfCurrentAssets) || 0;
        const parsedpurchaseOfLongTermAssets = parseFloat(purchaseOfLongTermAssets) || 0;
        const parseddividends = parseFloat(dividends) || 0;
      
        const calculatedSubTotalCashReceived =
        parsedrepaymentOfCurrentBorrowing +
        parsedlongTermLiabilitiesRepayment +
        parsedpurchaseOfCurrentAssets +
        parsedpurchaseOfLongTermAssets +
        parseddividends;
      
        setSubTotalCashSpent(calculatedSubTotalCashReceived);
        setTotalCashSpent(calculatedSubTotalCashReceived + (data?.expenseSubTotal || 0));
      }, [repaymentOfCurrentBorrowing, longTermLiabilitiesRepayment, purchaseOfCurrentAssets, purchaseOfLongTermAssets, dividends, data?.expenseSubTotal]);

      useEffect(()=>{
        const parsedCashReceived = parseFloat(totalCashReceived) || 0;
        const parsedcashSpent = parseFloat(totalCashSpent) || 0;
        setNetCashFlow((parsedCashReceived - parsedcashSpent).toFixed(2));
      },[totalCashReceived , totalCashSpent])

      useEffect(()=>{
        const parsednetCash = parseFloat(netCashFlow) || 0;
        setEndingCashBalance((parsednetCash + (data?.startingCashBalance || 0)).toFixed(2))
      },[netCashFlow])


      const onSubmit = () => {
        const originalDate = new Date( cashFlowPeriod);
        const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`;
        const serverData = new FormData()
        serverData.append('cashFlowPeriod', formattedDate);
        serverData.append('startingCashBalance', startingCashBalance);
        serverData.append('onlineSales', onlineSales);
        serverData.append('channelSales', channelSales);
        serverData.append('wholeSales', wholeSales);
        serverData.append('cashSubTotal', cashSubTotal);
        serverData.append('newCurrentBorrowing', newCurrentBorrowing);
        serverData.append('newLongTermLiabilities', newLongTermLiabilities);
        serverData.append('salesOfCurrentAssets', salesOfCurrentAssets);
        serverData.append('salesOfLongTermAssets', salesOfLongTermAssets);
        serverData.append('newInvestmentReceived', newInvestmentReceived);
        serverData.append('subTotalCashReceived', subTotalCashReceived);
        serverData.append('totalCashReceived', totalCashReceived);
        serverData.append('cashSpendingExpense', cashSpendingExpense);
        serverData.append('billPaymentExpense', billPaymentExpense);
        serverData.append('expenseSubTotal', expenseSubTotal);
        serverData.append('repaymentOfCurrentBorrowing', repaymentOfCurrentBorrowing);
        serverData.append('longTermLiabilitiesRepayment', longTermLiabilitiesRepayment);
        serverData.append('purchaseOfCurrentAssets', purchaseOfCurrentAssets);
        serverData.append('purchaseOfLongTermAssets', purchaseOfLongTermAssets);
        serverData.append('dividends', dividends);
        serverData.append('subTotalCashSpent', subTotalCashSpent);
        serverData.append('totalCashSpent',  totalCashSpent);
        serverData.append('netCashFlow', netCashFlow);
        serverData.append('endingCashBalance', endingCashBalance);
        axios({
            method: "POST",
            url:add_cash_flow,
            data: serverData,
        }).then(res => {
            if (!res.data.error) {
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
                setcashFlowPeriod(null)
                setStartingCashBalance('')
                setNewCurrentBorrowing('')
                setNewLongTermLiabilities('')
                setSalesOfCurrentAssets('')
                setSalesOfLongTermAssets('')
                setNewInvestmentReceived('')
                 setSubTotalCashReceived('')
                setTotalCashReceived('')
                setCashSpendingExpense('')
                setBillPaymentExpense('')
                setExpenseSubTotal('')
                setonlineSales('')
                setchannelSales('')
                setwholeSales('')
                setcashSubTotal('')
                setRepaymentOfCurrentBorrowing('')
                setLongTermLiabilitiesRepayment('')
                setPurchaseOfCurrentAssets('')
                setPurchaseOfLongTermAssets('')
                setDividends('')
                setSubTotalCashSpent('')
                setTotalCashSpent('')
                setNetCashFlow('')
                setEndingCashBalance('')
            } else {
              setOpen(true)
              setMessage(res.data.message)
              setStatus(false)
              setColor(false)
            }
        }).catch(err => {
            console.log(err)
        });
    }


  return (
      <Box p={1}>

<SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Box component={Card} p={3} boxShadow={4}>
    <Grid container>
    <Stack spacing={4} textAlign='left'>

    <Box pt={1} pb={1}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
    views={['year', 'month']}
    label="Year and Month"
    onChange={(newValue)=>setcashFlowPeriod(newValue)}
    value={cashFlowPeriod}
    renderInput={(params) => <TextField {...params} size='small' helperText={null} />}
    />
    </LocalizationProvider>
    </Box>

    <Box pt={1} pb={1}>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='h6'  fontWeight={600}>Starting Cash Balance</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={startingCashBalance}
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box> 
    </Box>
  

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(A) Cash Received</Typography>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1A) Web Sales</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={onlineSales}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2A) Channel Sales</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={channelSales}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3A) Whole Sales</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={wholeSales}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(4A) Subtotal Cash from Sales</Typography>
    <Typography variant='caption'>(1A + 2A + 3A)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={cashSubTotal}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>

    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(B) Additional Cash Received</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1B) New Current Borrowing</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <CustomTextField
    value={newCurrentBorrowing}
    onChange={setNewCurrentBorrowing}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2B) New Long-Term Liabilities</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
    value={newLongTermLiabilities}
    onChange={setNewLongTermLiabilities}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3B) Sales of Current Assets</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <CustomTextField
    value={salesOfCurrentAssets}
    onChange={setSalesOfCurrentAssets}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(4B) Sales of Long-Term Assets</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
   value={salesOfLongTermAssets}
   onChange={setSalesOfLongTermAssets}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(5B) New Investment Received</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
    value={newInvestmentReceived}
    onChange={setNewInvestmentReceived}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(6B) Subtotal Cash Received</Typography>
    <Typography variant='caption'>(1B + 2B + 3B + 4B + 5B)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={subTotalCashReceived}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(7B) Total Cash Received</Typography>
    <Typography variant='caption'>(4A + 6B)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalCashReceived}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    </Stack>
    

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(C) Expenditures</Typography>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1C) Cash Spending</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={cashSpendingExpense}
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2C) Bill Payments</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={billPaymentExpense}
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
  <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(3C) Expenses Subtotal</Typography>
    </Grid>
  <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
     value={expenseSubTotal}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>

    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(D) Additional Cash Spent</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1D) Repayment of Current Borrowing</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <CustomTextField
    value={repaymentOfCurrentBorrowing}
    onChange={setRepaymentOfCurrentBorrowing}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2D) Long-Term Liabilities Repayment</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
        <CustomTextField
    value={longTermLiabilitiesRepayment}
    onChange={setLongTermLiabilitiesRepayment}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3D) Purchase of Current Assets</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
    value={purchaseOfCurrentAssets}
    onChange={setPurchaseOfCurrentAssets}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(4D) Purchase of Long-Term Assets</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
    value={purchaseOfLongTermAssets}
    onChange={setPurchaseOfLongTermAssets}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(5D) Dividends</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
   <CustomTextField
    value={dividends}
    onChange={setDividends}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(6D) Subtotal Cash Spent</Typography>
    <Typography variant='caption'>(1D + 2D + 3D + 4D + 5D)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={subTotalCashSpent}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(7D) Total Cash Spent</Typography>
    <Typography variant='caption'>(3C + 6D)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalCashSpent}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>


    </Stack>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2} pt={2}>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>Net Cash Flow</Typography>
    <Typography variant='caption'>(7B + 7D)</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={netCashFlow}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>Ending Cash Balance</Typography>
    </Grid>
   <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={endingCashBalance}
    variant="outlined"
    sx={{ width: '25ch' }}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>
    </Stack>


    <Box>
    <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center'>
    <Button onClick={onSubmit} variant='contained'  sx={{ ml:6,  color: '#fff', width: '20ch' , bgcolor: '#000', ':hover': { bgcolor: '#faa634'} }}>Submit</Button> 
    </Grid>
    </Box>

    </Stack>
    </Grid>
    </Box>


      </Box>
  )
}
