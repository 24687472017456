import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Grid, Button, Box, Typography, Card, } from '@mui/material';
import Heading from '../../Components/Heading/Heading';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from 'axios';
import { add_return_product, get_all_product_name, get_user_ordered_product_refund } from '../../Services/APIService';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

export default function ReturnSale() {


    const [tableRows, setTableRows] = useState([{}]); // Initial row
    const [orderNumber , setOrderNumber] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [productList, setProductList] = useState([]);
    const [BasicData, setBasicData] = useState();
    const [ReturnDate, setReturnDate] = useState("");


    const handleAddRow = () => {
        const newRow = {
            productId: '',
            productName: '',
            productPrice: '',
            productColor:'',
            orderedQty:'',
            subtotal: '',
        };
        setTableRows((prevRows) => [...prevRows, newRow]);
    };

    const handleDeleteRow = (index) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows.splice(index, 1);
            return updatedRows;
        });
    };

    const getRefundOrderData = () => {
        const sendData = new FormData()
        sendData.append('orderNum', orderNumber)
        axios({
            method: "POST",
            url: get_user_ordered_product_refund,
            data:sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setBasicData(res.data.data);
                const products = res.data.data.products || [];
                const productRows = products.map((product, index) => ({
                    productId: product.productId,
                    productName: product.productName,
                    orderedQty: product.orderedQty,
                    productPrice: product.productPrice,
                    productColor: product.productColor,
                    subtotal: product.orderedPrice,
                }));
                setTableRows(productRows);
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        axios({
            method: "GET",
            url: get_all_product_name,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setProductList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    const Productoptions = productList.map((i) => {
        const obj = {
            'label': i.productName,
            'id': i.productId
        }
        return obj;
    });


    const product = {};

    tableRows && tableRows.forEach((item, index) => {
        const productItem = {
            productId: item.productId,
            productName: item.productName,
            productPrice: item.productPrice,
            productQty: item.orderedQty,
            productColor: item.productColor,
            subTotal: item.subtotal,
        };
        product[index] = productItem;
    });

    const onSubmit = () => {
      
        if (ReturnDate !== '' & orderNumber !== ''){
            const serverData = new FormData()
            serverData.append('orderNum', orderNumber);
            serverData.append('customerName', BasicData?.customerName);
            serverData.append('email', BasicData?.email);
            serverData.append('total', BasicData?.orderCost);
            serverData.append('returnDate', ReturnDate)
            serverData.append('orderDate', BasicData?.orderedDate);
            serverData.append(' returnProduct', JSON.stringify(product));
            axios({
                method: "POST",
                url: add_return_product,
                data: serverData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setTableRows([{}]);
                    setReturnDate('');
                }
            }).catch(err => {
                console.log(err)
            });
        }
        else{
            setOpen(true)
            setMessage('Fill all the Fields')
            setStatus(false)
            setColor(false)
        }
    }


    return (
        <Box py={1}>
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
            <Container>
                <Box mt={1} py={2}  >
                    <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} py={2}>
                                <Grid container spacing={2} justifyContent='start'>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <TextField 
                                        id="outlined-basic" 
                                        label='Order Number'
                                         size='small' 
                                         variant="outlined" 
                                         fullWidth 
                                         value={orderNumber}
                                         onChange={(e)=>setOrderNumber(e.target.value)}
                                         />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} display='flex' justifyContent='start'>
                                    <Button variant='contained' color='warning' onClick={getRefundOrderData}>GET ORDER</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        <TableContainer component={Card} boxShadow={0}>
                            <Table bgcolor='#eff8f2'>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }} colSpan={4}>
                                            Product
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Cost</TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Quantity</TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Color</TableCell>
                                        <TableCell align="center" colSpan={3} >Sub Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell colSpan={4} align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={Productoptions}
                                                    value={row.productName || ''}
                                                    id="combo-box-demo"
                                                    size='small'
                                                    sx={{ width: 180 }}
                                                    renderInput={(params) => <TextField {...params} label="Select a Product" />}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <TextField 
                                                id="outlined-basic" 
                                                size='small' 
                                                sx={{ width: 100 }} 
                                                variant="outlined"
                                                value={row.productPrice}
                                                 />
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <TextField
                                                 id="outlined-basic"
                                                  size='small' 
                                                  sx={{ width: 100 }}
                                                   variant="outlined"
                                                    value={row.orderedQty}
                                                    />
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <TextField 
                                                id="outlined-basic" 
                                                size='small'
                                                sx={{ width: 100 }}
                                                variant="outlined"
                                                value={row.productColor}
                                                 />
                                            </TableCell>
                                            <TableCell align="center" colSpan={3} >
                                                <Box display='flex' gap={4} justifyContent='end'>
                                                    <TextField 
                                                    id="outlined-basic"
                                                     size='small' 
                                                     variant="outlined"
                                                    value={row.subtotal}
                                                    />

                                                    {
                                                        index > 0 ?
                                                            <HighlightOffIcon onClick={() => handleDeleteRow(index)} sx={{ verticalAlign: 'middle' }} color='error' fontSize='large' />
                                                            :
                                                            <AddBoxIcon onClick={handleAddRow} sx={{ verticalAlign: 'middle' }} color='primary' fontSize='large' />
                                                    }
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Return Date</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    type='date'
                                                    onChange={(e) => setReturnDate(e.target.value)}
                                                    value={ReturnDate}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Customer Name</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.customerName}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Email</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.email}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Phone Number</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.phoneNum}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Invoice Number</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        className: 'custom-input-class',
                                                        style: { fontSize: '0.85rem' },
                                                    }}
                                                    value={BasicData?.invoiceNum}
                                                />
                                            </TableCell>
                                        </TableRow>

                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Date Of Purchase</TableCell>
                                        <TableCell align="center" > 
                                        <TextField 
                                        id="outlined-basic" 
                                        size='small' 
                                        variant="outlined"
                                        type='date'
                                        fullWidth
                                        value={BasicData?.orderedDate}
                                        />
                                        </TableCell>
                                    </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Total Product Price</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.orderCost}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Tax Cost</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.taxCost}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Paid Amount</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={BasicData?.totalCost}
                                                />
                                            </TableCell>
                                        </TableRow>


                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="right" colSpan={6}></TableCell>
                                            <TableCell align="center"> <Button color='warning' onClick={onSubmit} variant='contained' fullWidth>Submit</Button></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Box>
            </Container>
            </Box>
        </Box>
    );
}
