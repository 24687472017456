import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { get_all_brand_names, get_home_image, get_wholesale_trendz, get_wholesale_trendz_banner, getallcategory } from '../../../Services/APIService';
import axios from 'axios';
import TrendingLayout from './TrendingLayout';
import BannerText from './BannerText';


export default function TredingTab() {

    const [value, setValue] = React.useState(0);
    const [TrendingSection1, setTrendingSection1] = useState([])
    const [TrendingSection2, setTrendingSection2] = useState([])
    const [TrendingSection3, setTrendingSection3] = useState([])
    const [TrendingSection4, setTrendingSection4] = useState([])
    const [TrendingSection5, setTrendingSection5] = useState([])
    const [TrendingSection6, setTrendingSection6] = useState([])

    const [TrendingSection7, setTrendingSection7] = useState([])
    const [TrendingSection8, setTrendingSection8] = useState([])
    const [TrendingSection9, setTrendingSection9] = useState([])
    const [TrendingSection10, setTrendingSection10] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [BrandData  , setBrandData] = useState([]);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");

    const [bannerHeading , setBannerHeading] = useState('');
    const [bannerCaption , setBannerCaption]= useState('');
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        getAllData();
    }, [])



    const getAllData = () => {
        const sendData = new FormData()
        sendData.append('homePageImageId', '');
        axios({
          method: 'POST',
          url: get_wholesale_trendz,
          data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setTrendingSection1(res.data.data.Trending_layout_1)
                setTrendingSection2(res.data.data.Trending_layout_2)
                setTrendingSection3(res.data.data.Trending_layout_3)
                setTrendingSection4(res.data.data.Trending_layout_4)
                setTrendingSection5(res.data.data.Trending_layout_5)
                setTrendingSection6(res.data.data.Trending_layout_6)
                setTrendingSection7(res.data.data.Trending_layout_7)
                setTrendingSection8(res.data.data.Trending_layout_8)
                setTrendingSection9(res.data.data.Trending_layout_9)
                setTrendingSection10(res.data.data.Trending_layout_10)
                setOpen(true)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }


    useEffect(() => {
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])



    const CategoryOptions = categoryList.map((i) => {
        const obj = {
            'label': i.categoriesName,
            'id': i.categoryId,
        }
        return obj;
    });


    const getBannerText = () => {
        const sendData = new FormData()
        sendData.append('bannerId', 2);
        axios({
          method: 'POST',
          url: get_wholesale_trendz_banner,
          data: sendData,
        }).then(res => {
            if (!res.data.error) {
                setBannerHeading(res.data.data.bannerHeading);
                setBannerCaption(res.data.data.bannerCaption);
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }
   
    useEffect(()=>{
        getBannerText();
    },[])



    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                    label="Banner Text"
                    sx={{
                    bgcolor: value === 0 && '#00bca4',
                    border: value === 0 && '1px solid silver',
                    borderRadius: value === 0 && '4px',
                    textTransform: 'none'
                    }}
                    {...a11yProps(0)} />
                    <Tab
                        label="Trending Layout 1"
                        sx={{
                            bgcolor: value === 1 && '#00bca4',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)} />
                    <Tab
                        label="Trending Layout 2"
                        sx={{
                            bgcolor: value === 2 && '#00bca4',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(2)}
                    />
                    <Tab
                        label="Trending Layout 3"
                        sx={{
                            bgcolor: value === 3 && '#00bca4',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 && 3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(3)}
                    />
                    <Tab
                        label="Trending Layout 4"
                        sx={{
                            bgcolor: value === 4 && '#00bca4',
                            border: value === 4 && '1px solid silver',
                            borderRadius: value === 4 && 4,
                            textTransform: 'none'
                        }}
                        {...a11yProps(4)}
                    />
                    <Tab
                        label="Trending Layout 5"
                        sx={{
                            bgcolor: value === 5 && '#00bca4',
                            border: value === 5 && '1px solid silver',
                            borderRadius: value === 5 && 5,
                            textTransform: 'none'
                            }}
                            {...a11yProps(5)}
                    />
                        <Tab
                        label="Trending Layout 6"
                        sx={{
                            bgcolor: value === 6 && '#00bca4',
                            border: value === 6 && '1px solid silver',
                            borderRadius: value === 6 && 6,
                            textTransform: 'none'
                            }}
                            {...a11yProps(6)}
                        />

                        <Tab
                        label="Trending Layout 7"
                        sx={{
                            bgcolor: value === 7 && '#00bca4',
                            border: value === 7 && '1px solid silver',
                            borderRadius: value === 7 && 7,
                            textTransform: 'none'
                            }}
                            {...a11yProps(7)}
                        />


                        <Tab
                        label="Trending Layout 8"
                        sx={{
                            bgcolor: value === 8 && '#00bca4',
                            border: value === 8 && '1px solid silver',
                            borderRadius: value === 8 && 8,
                            textTransform: 'none'
                            }}
                            {...a11yProps(8)}
                        />

                        <Tab
                        label="Trending Layout 9"
                        sx={{
                            bgcolor: value === 9 && '#00bca4',
                            border: value === 9 && '1px solid silver',
                            borderRadius: value === 9 && 9,
                            textTransform: 'none'
                            }}
                            {...a11yProps(9)}
                        />
                        <Tab
                        label="Trending Layout 10"
                        sx={{
                        bgcolor: value === 10 && '#00bca4',
                        border: value === 10 && '1px solid silver',
                        borderRadius: value === 10 && 10,
                        textTransform: 'none'
                        }}
                        {...a11yProps(10)}
                        />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BannerText screen={'Trendz'} id={2} bannerCaption={bannerCaption} bannerHeading={bannerHeading} setBannerCaption={setBannerCaption} setBannerHeading={setBannerHeading} getAllData={getBannerText} />
            </TabPanel>

            <TabPanel value={value} index={1} >
                <TrendingLayout screen={'Trendz'} Dimensions={'1920 * 2880 '}  CategoryOptions={CategoryOptions} section={'Trending_layout_1'} Layout={TrendingSection1} getAllData={getAllData} />
            </TabPanel>
           
            <TabPanel value={value} index={2}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}  CategoryOptions={CategoryOptions} section={'Trending_layout_2'} Layout={TrendingSection2} getAllData={getAllData}  />
            </TabPanel>

            <TabPanel value={value} index={3}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_3'} Layout={TrendingSection3} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={4}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}  CategoryOptions={CategoryOptions} section={'Trending_layout_4'} Layout={TrendingSection4} getAllData={getAllData} />
            </TabPanel>


            <TabPanel value={value} index={5}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_5'} Layout={TrendingSection5} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={6}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_6'} Layout={TrendingSection6} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={7}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_7'} Layout={TrendingSection7} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={8}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_8'} Layout={TrendingSection8} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={9}>
                <TrendingLayout screen={'Trendz'} Dimensions={'385 * 300'}   CategoryOptions={CategoryOptions} section={'Trending_layout_9'} Layout={TrendingSection9} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={10}>
                <TrendingLayout screen={'Trendz'} Dimensions={'1920 * 2880'}   CategoryOptions={CategoryOptions} section={'Trending_layout_10'} Layout={TrendingSection10} getAllData={getAllData} />
            </TabPanel>

        </Container>
    )
}
