import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, Link, Avatar, IconButton } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { ProfileImageAPI, getuser } from '../../Services/APIService';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import ResponsiveBd from './ResponsiveBd';

function DashbrdHeader({ mobileOpen, setMobileOpen }) {
  const navigate = useNavigate();
  const userId = localStorage.getItem('AdminUserId');
  const [profile , setProfile] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [search , setSearch] = useState('');

  const responsive = ResponsiveBd();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

    useEffect(()=>{
      const serverData = new FormData()
      serverData.append('userId', userId);
      axios({
      method: "POST",
      url: getuser,
      data:serverData
  }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setProfile(res.data.data);
            setStatus(true)
            setColor(true)
        }
    }).catch(err => {
        console.log(err)
    });
},[])

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

      const handleClick = () =>{
          navigate('notifications');
      }

    return(
    <Box component={Card} py={2} display='flex' justifyContent='space-between' alignContent='center' square={true}>
      <Box>
          <IconButton aria-label="menu"
            sx={{
              display: responsive ? 'block': 'none'
            }} 
            onClick={handleDrawerToggle} >
            <MenuIcon />
          </IconButton>
      </Box>
  <Box display='flex' justifyContent='end' alignContent='center' alignItems='center' square={true}>
        <Box ml={1}>
        <NotificationsIcon onClick={handleClick} sx={{verticalAlign:'middle', cursor:'pointer'}}/>
        </Box>
        <Box ml={1} mr={2}>
        <Avatar
            alt={`${profile.first_name}${profile.last_name}`}
            src={`${ProfileImageAPI}${profile.userProfile}`}
            sx={{ width: 24, height: 24, bgcolor:'#faa634' }}
            />
        </Box>
        </Box>
    </Box>
    )
}

export default DashbrdHeader;