import { Box, Typography, Card, Grid, TextField, Button, Autocomplete, Stack, InputLabel, Select, MenuItem } from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { add_main_category, add_product_image, add_products, get_all_product_name, getallcategory } from '../../Services/APIService';
import axios from 'axios';
import { Category } from '@mui/icons-material';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from 'react-bootstrap';

export default function ProductsPage() {


  const {register, formState: { errors }, handleSubmit, reset} = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const[categoryList , setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const[selectedCategory , setSelectedCategory] = useState("");
  const[isBulkOrder , setIsBulkOrder] = useState("");
  const [LaunchingSoon, setLaunchingSoon] = useState("");
  const [ComboColor, setComboColor] = useState("");
  const [ColorCode, setColorCode] = useState("");
  const [SoldOut, setSoldOut] = useState("");
  const [Publish, setPublish] = useState("");
  const [mainImage , setmainImage] = useState('');
  const [productImage , setproductImage] = useState([]);
  const [productVideo , setproductVideo] = useState('');
  const [input, setInput] = useState(false);
  const [productDescription, setProductDescription] = useState('')
  const [productOverview, setProductOverview] = useState('')
  const [Description, setDescription] = useState('')
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [productColor, setProductColor] = useState('');
  const [PreviewImagesByColor , setPreviewImagesByColor] = useState([]);
  const [BackDropText, setBackDropText] = useState('');

useEffect(()=>{
      axios({
      method: "GET",
      url: getallcategory,
  }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setMessage(res.data.message)
            setCategoryList(res.data.data);
            setStatus(true)
            setColor(true)
        }
    }).catch(err => {
        console.log(err)
    });
},[])

 const options = categoryList.map((i)=>{
  const obj = {
    'label':i.categoriesName,
    'id':i.categoryId
  }
  return obj ;
 });

 const getAllProductsNames = () =>{
   axios({
     method: "GET",
     url: get_all_product_name,
   }).then(res => {
     if (res.data.error) {
       setMessage(res.data.message)
       setOpen(true)
       setStatus(false)
       setColor(false)
     } else {
       setProductList(res.data.data);
     }
   }).catch(err => {
     console.log(err)
   });
 }

  useEffect(() => {
    getAllProductsNames();
  }, [])

  const Productoptions = productList.map((i) => {
    const obj = {
      'label': i.productName,
      'id': i.productId
    }
    return obj;
  });


  const onSubmit = (data) => {
    setOpenBackdrop(true);
    setBackDropText('Adding New Product....')
    const serverData = new FormData()
    for (var key in data) {
        serverData.append(key, data[key]);
    }
    serverData.append('categoryId',selectedCategory.id);
    serverData.append('isBulkOrder', "0");
    serverData.append('mainImage',mainImage);
    serverData.append('productVideo',productVideo);
    serverData.append('launchingSoon', LaunchingSoon);
    serverData.append('comboColor', ComboColor);
    serverData.append('colorCode', ColorCode);
    serverData.append('soldOut', SoldOut);
    serverData.append('publish', Publish);
    serverData.append('productDescription', `Product Overview:\n${productOverview}\n\nDescription:\n${Description}`);
    axios({
      method: "POST",
      url: add_products,
      data: serverData,
  }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            setOpenBackdrop(false);
        } else {
            setOpen(true)
            setMessage('Product Added Successfully')
            setStatus(true)
            setColor(true)
            document.getElementById('pic1').value='';
            document.getElementById('pic3').value='';
            setProductOverview('');
            setDescription('');
            setSelectedCategory('');
            setInput(true);
            setOpenBackdrop(false);
            getAllProductsNames();
            setIsBulkOrder("");
            reset({
              productName:'',
              productPrice:'',
              productWeight:'',
              productQty:'',
              productColor:'',
              productDiscountPercent:'',
              productLeadTime:'',
              productMRP:'',
              productHeight:'',
              productWidth:'',
              productLength:'',
            })
        }
    }).catch(err => {
        console.log(err)
    });
  }

// Preview Images

  const LocalStoredImage = () => {
    if (productColor !== '' && productImage.length !== 0) {
      const newObject = { color: productColor, Images: productImage };
      setPreviewImagesByColor((prevImages) => [...prevImages, newObject]);
      setProductColor("");
      document.getElementById('previewImage').value = '';
    }
  };



  const renderImages = () => {
    return PreviewImagesByColor.map((item, index) => (
    <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center" display="flex" justifyContent="center" flexDirection="column" key={index}>
    <Box textAlign="left" px={2}>
    <Typography sx={{ marginBottom: 1, textDecoration: 'underline' }} fontWeight={500} variant="subtitle1" py={2}>
    {item.color} :
    </Typography>
    <Box display="flex" flexDirection="row" justifyContent="start" gap={2}>
    {Array.from(item.Images).map((image, imageIndex) => {
    // Check if createObjectURL method is supported
    const imageUrl = typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function' ? URL.createObjectURL(image) : '';
    return (
    <img
    key={imageIndex}
    src={imageUrl}
    alt={`Product Image ${imageIndex + 1}`}
    style={{ width: '80px', height: '80px', verticalAlign: 'middle' }}
    />
    );
    })}
    <Box>
    <CloseIcon sx={{ verticalAlign: 'middle' , my:3}} color="primary" fontSize="large" onClick={() => removeImageByColor(index)} />
    </Box>
    </Box>
    </Box>
    </Grid>
    ));
  };


  const removeImageByColor = (colorIndex) => {
    const updatedPreviewImages = [...PreviewImagesByColor];
    updatedPreviewImages.splice(colorIndex, 1);
    setPreviewImagesByColor(updatedPreviewImages);
  };


  const handleInsertImages = () => {
    setOpenBackdrop(true);
    setBackDropText('Inserting New Images....')
    PreviewImagesByColor.forEach(data => {
      const { color, Images } = data;

      // Create FormData and append data
      const serverData = new FormData();
      serverData.append('productId', selectedProduct.id);
      serverData.append('productColor', color);
      Images.forEach((image, index) => {
        serverData.append(`productImage[${index}]`, image);
      });

      // Make the API call for each element
      axios
        .post(add_product_image, serverData)
        .then(res => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setOpen(true);
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            setOpenBackdrop(false);
            setPreviewImagesByColor([]);
            setSelectedProduct('');
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

 
  return (
   <Box p={2}>
     <Box p={2}>

        <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <Box display='flex' flexDirection='row' gap={2}>
          <Box py={1}>
              <Typography>{BackDropText}</Typography>
          </Box>
            <CircularProgress color="secondary" />
        </Box>
      </Backdrop>


   {/* Products Info Insertion */}
     
   <Box py={3} mt={1}>
        <Heading  title={'New Product'}/>
    </Box>
        <form onSubmit={handleSubmit(onSubmit)} id='form1' >
          <Box component={Card} py={3} mt={1} boxShadow={5}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
          <Box textAlign='left' p={2}>
          <Typography variant='h6' fontWeight={500}>Insert Products Informations</Typography>
          </Box>
          <Grid container display='flex' flexDirection={{xs:'column', sm:'cloumn', md:'row', lg:'row'}}>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
            disablePortal
              id="combo-box-demo"
              size='medium'
              value={selectedCategory}
              key={input}
              onChange={(index, value)=>setSelectedCategory(value)}
              options={[...options]}
              renderInput={(params) => <TextField {...params} sx={{m:2, width:'25ch'}} required size='small' label="Select Category" />}
            />
            </Grid>


            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
            disablePortal
              id="combo-box-demo"
              size='medium'
              value={isBulkOrder}
              key={input}
              onChange={(index, value)=>setIsBulkOrder(value)}
              options={['YES' , 'NO']}
              renderInput={(params) => <TextField {...params} sx={{m:2, width:'25ch'}} required size='small' label="Is Bulk Order" />}
            />
            </Grid> */}

          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productName? true : false}
            helperText={errors.productName ? "Product Name is required" : ""}
            {...register("productName", { required: true })} 
            size='small'
            type='text'
            label="Product Name"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productQty? true : false}
            helperText={errors.productQty ? "Product Qty is required" : ""}
            {...register("productQty", { required: true })} 
            size='small'
            type='text'
            label="Product Qty"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>


          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productWeight? true : false}
            helperText={errors.productWeight ? "Product Weight is required" : ""}
            {...register("productWeight")} 
            size='small'
            type='text'
            label="Product Weight (Gram)"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>


          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
          error={errors.productLength ? true : false}
          helperText={errors.productLength ? "Product Length is required" : ""}
          {...register("productLength")} 
          size='small'
          type='text'
          label="Product Length (Inch)"
          sx={{m:2, width:'25ch'}}
          variant="outlined" />
            </Grid>


          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
          required
          error={errors.productWidth ? true : false}
          helperText={errors.productWidth ? "Product Width is required" : ""}
          {...register("productWidth")} 
          size='small'
          type='text'
          label="Product Width (Inch)"
          sx={{m:2, width:'25ch'}}
          variant="outlined" />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
          error={errors.productHeight ? true : false}
          helperText={errors.productHeight ? "Product Height is required" : ""}
          {...register("productHeight")} 
          size='small'
          type='text'
          label="Product Height (Inch)"
          sx={{m:2, width:'25ch'}}
          variant="outlined" />
          </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productMRP? true : false}
            helperText={errors.pproductMRP? "Product MRP Price is required" : ""}
            {...register("productMRP")} 
            size='small'
            type='text'
            label="MRP Price"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productPrice? true : false}
            helperText={errors.productPrice? "Product Price is required" : ""}
            {...register("productPrice", { required: true })} 
            size='small'
            type='text'
            label="Product Price"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            size='small'
            type='file'
            id='pic1'
            onChange={(e)=>setmainImage(e.target.files[0])}
            label="Product Main Image"
            sx={{m:2, width:'25ch'}}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            helperText='(Must Select One Image)'
            />
            </Grid>

             <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            size='small'
            type='file'
            id='pic3'
            onChange={(e)=>setproductVideo(e.target.files[0])}
            label="Product Video"
            sx={{m:2, width:'25ch'}}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }} 
            />
            </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required
            error={errors.productLeadTime? true : false}
            helperText={errors.productLeadTime? "Product Lead Time is required" : ""}
            {...register("productLeadTime", { required: true })} 
            size='small'
            type='text'
            label="Product Lead Time"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            error={errors.productDiscountPercent ? true : false}
            helperText={errors.productDiscountPercent ? "Discount is required" : ""}
            {...register("productDiscountPercent")} 
            size='small'
            type='text'
            label="Discount Precent"
            sx={{m:2, width:'25ch'}}
            variant="outlined" />
            </Grid>

           <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
            disablePortal
              id="combo-box-demo"
              size='medium'
              value={LaunchingSoon}
              key={input}
              onChange={(index, value)=>setLaunchingSoon(value)}
              options={['yes' , 'no']}
              renderInput={(params) => <TextField {...params} sx={{m:2, width:'25ch'}} required size='small' label="Launching Soon" />}
            />
            </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      value={SoldOut}
                      key={input}
                      onChange={(index, value) => setSoldOut(value)}
                      options={['yes', 'no']}
                      renderInput={(params) => <TextField {...params} sx={{ m: 2, width: '25ch' }} required size='small' label="Sold Out *" />}
                    />
                  </Grid>



                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      value={ComboColor}
                      key={input}
                      onChange={(index, value) => setComboColor(value)}
                      options={['yes', 'no']}
                      renderInput={(params) => <TextField {...params} sx={{ m: 2, width: '25ch' }} required size='small' label="Combo Color" />}
                    />
                  </Grid>


{
  ComboColor === 'yes' && 
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          onChange={(e) => setColorCode(e.target.value)}
                          helperText={"Eg: 'Assorted' , 'Set Of 3'"}
                          size='small'
                          type='text'
                          required
                          label="Color Code"
                          sx={{ m: 2, width: '25ch' }}
                          variant="outlined" />
                    </Grid>
}


                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      value={Publish}
                      key={input}
                      onChange={(index, value) => setPublish(value)}
                      options={['yes', 'no']}
                      renderInput={(params) => <TextField {...params} sx={{ m: 2, width: '25ch' }} required size='small' label="Publish" />}
                    />
                  </Grid>



                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box p={2}>
                      <Typography fontWeight={600} textAlign='left' variant='subtitle1'>Product Description</Typography>
                    </Box>
                  <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} sm={12} md={5} lg={5} mx={1}>
                    <TextField
                      size='small'
                      type='text'
                      multiline
                      rows={4}
                      label="Product Overview"
                      
                      value={productOverview}
                      fullWidth
                      onChange={(e) => setProductOverview(e.target.value)}
                      variant="outlined" />
                  </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}  mx={1}>
                        <TextField
                          size='small'
                          type='text'
                          multiline
                          rows={4}
                          fullWidth
                          value={Description}
                          onChange={(e) => setDescription(e.target.value)}
                          label="Description"
                         
                          variant="outlined" />
                      </Grid>
                    </Grid>
                  </Grid>
            </Grid>

            <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box p={2}>
                      <Button form='form1' type='submit' variant='contained' fullWidth
                        sx={{bgcolor: '#faa634', ':hover': { bgcolor: '#316C31' } }}>
                        create New Product</Button>
                    </Box>
                  </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Box>
    </form>


        <Box py={3} mt={2}>
          <Heading title={'Add Preview Images'} />
        </Box>
     
          <Box component={Card} py={3} mt={1} boxShadow={5}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box textAlign='left' p={2}>
                <Typography variant='h6' fontWeight={500}>Insert Product's Preview Images</Typography>
                <Typography variant='caption' fontWeight={500} px={2} color='#616e80'>* Add Color and Their Respective Images One By One *</Typography>
                </Box>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size='medium'
                  value={selectedProduct}
                  key={input}
                  onChange={(index, value) => setSelectedProduct(value)}
                  options={[...Productoptions]}
                  renderInput={(params) => <TextField {...params} sx={{ m: 2, width: '25ch' }} required size='small' label="Select a Product" />}
                />
              </Grid>

                <Grid container display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }}>
               
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box p={2}>
                    <Typography fontWeight={600} textAlign='left' variant='subtitle1'>Product Preview Images</Typography>
                  </Box>
                </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                     onChange={(e) => setProductColor(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                      size='small'
                      type='text'
                      label="Product Color"
                      value={productColor}
                      sx={{ m: 2, width: '25ch' }}
                      variant="outlined" />
                  </Grid>


                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      size="small"
                      type="file"
                      id="previewImage"
                      multiple
                      onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setproductImage(files);
                      }}
                      label="Product Preview Images"
                      sx={{ m: 2, width: '25ch' }}
                      helperText="(Upload at least 3 Images)"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        multiple: true,
                        accept: 'image/*', // Specify the file types to accept
                      }}
                    />
                  </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Button variant='contained' color='primary' sx={{ m: 2, width: '25ch' }} onClick={LocalStoredImage}>Save</Button>
                </Grid>

                <Grid container>
                  <Box py={2}>
                    {renderImages()}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
                  <Button variant='contained' fullWidth color='warning' onClick={handleInsertImages}>Insert Image</Button>
                </Grid>

              </Grid>
            </Grid>
            </Grid>
          </Box>
        

     </Box>
   </Box>
  )
}
