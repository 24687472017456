import axios from 'axios';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, Card, Grid, Stack, TextField, Container } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from "@mui/material";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import StoreIcon from '@mui/icons-material/Store';
import LanguageIcon from '@mui/icons-material/Language';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CustomTextField from '../../../Components/CustomTextField';
import { add_bank_statement, excel_bank_statement, get_bank_statement } from '../../../Services/APIService';
import SnackBar from '../../../Components/AdminDashBoardComps/Snackbar';
import { ExportBankStmnt } from './ExportBankStmnt';
import Filter from '../../../Components/FilterData/Filter';
import { FilterBankTwoData } from '../../../Components/FilterData/FilterData';

export default function RBCAccount() {

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
   const[data , setData] = useState([]);
  const [openingBalanceOn, setOpeningBalanceOn] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [totalDepositsCredits, setTotalDepositsCredits] = useState('');
  const [totalChequesDebits, setTotalChequesDebits] = useState('');
  const [accountFees, setAccountFees] = useState('');
  const [closingBalanceOn, setClosingBalanceOn] = useState('');
  const [closingBalance, setClosingBalance] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const AdminId = localStorage.getItem('AdminUserId'); 

  const [openingBalanceOnSearch , setopeningBalanceOnSearch] = useState('')
  const [closingBalanceOnSearch , setclosingBalanceOnSearch] = useState('')

  const [buttonName , setButtonName] = useState('Upload file')
  const [buttonColor, setButtonColor] = useState('primary');
  const [OutBoundfile, setOutBoundfile] = useState(null);


  const getAllStatements = ()=>{
    const sendData = new FormData()
    sendData.append('bankStatementId' , '')
    axios({
         method:'POST',
         url: get_bank_statement,
         data:sendData
     }).then(res => {
         if (res.data.error) {
             setMessage(res.data.message)
             setOpen(true)
             setStatus(false)
             setColor(false)
         } else {
             setMessage(res.data.message)
             setStatus(true)
             setColor(true)
             setData(res.data.data);
         }
     }).catch(err => {
         alert('Oops something went wrong ' + err)
     });
    }

  useEffect(()=>{
  getAllStatements();
  },[]);


  const keys = Object.keys(data[0] || {}).filter((key) => key !== "bankStatementId");

  const handleChangePage = (event, newPage) => {
  setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
  };

  const onSubmit = () => {
  const serverData = new FormData()
  serverData.append('adminId', AdminId);
  serverData.append('openingBalanceOn', openingBalanceOn);
  serverData.append('openingBalance', openingBalance);
  serverData.append('totalDepositsCredits', totalDepositsCredits);
  serverData.append('totalChequesDebits', totalChequesDebits);
  serverData.append('accountFees', accountFees);
  serverData.append('closingBalanceOn', closingBalanceOn);
  serverData.append('closingBalance', closingBalance);
  axios({
    method: "POST",
    url:add_bank_statement,
    data: serverData,
  }).then(res => {
    if (!res.data.error) {
      setOpen(true)
      setMessage(res.data.message)
      setStatus(true)
      setColor(true)
      getAllStatements();
    setOpeningBalanceOn('');
    setOpeningBalance('');
    setTotalDepositsCredits('');
    setTotalChequesDebits('');
    setAccountFees('');
    setClosingBalanceOn('');
    setClosingBalance('');
    } else {
      setOpen(true)
      setMessage(res.data.message)
        setStatus(false)
        setColor(false)
    }
  }).catch(err => {
    console.log(err)
  });
  }


  const importStatment = (e) =>{
    const file = e.target.files[0];
    setOutBoundfile(file);
    if (file === null || file === undefined) {
      setMessage('File is required');
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      const sendData = new FormData();
      sendData.append('outbound_file', file);
      axios({
        method: 'POST',
        url: excel_bank_statement,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage('Please Try Again');
            setOpen(true);
            setStatus(false);
            setColor(false);
            setButtonName('Upload file')
          } else {
            setOpen(true);
            setMessage('Excel file uploaded successfully');
            setStatus(true);
            setColor(true);  
            setOutBoundfile(null)
            setButtonName('File Uploaded')
            getAllStatements();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  return (
    <Box p={3}>

   <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Stack spacing={5}>

    <Stack spacing={1}>
      <Box sx={{cursor:'pointer'}} p={2} textAlign='left'>
      <Typography variant='h5' sx={{color:'#faa634' , cursor:'pointer'}}>Bank Balance Manual Updation</Typography>
      </Box>

      <Box component={Card} p={4} boxShadow={4} maxWidth={600}>
      <Grid container>
      <Stack spacing={3} pb={2} textAlign='left'>
      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Opening Balance On</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <TextField 
      size='small' 
      variant="outlined"
      type='date'
      fullWidth
      onChange={(e)=>setOpeningBalanceOn(e.target.value)}
      value={openingBalanceOn}
      />
      </Grid>
      </Box>

      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Opening Balance Amount</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <CustomTextField
      value={openingBalance}
      onChange={setOpeningBalance}
      validationRegex={/^[0-9]*\.?[0-9]*$/}
      errorMessage={"Only Numeric Values are Allowed"}
      />
      </Grid>
      </Box>

      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Deposits & Credits</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <CustomTextField
      value={totalDepositsCredits}
      onChange={setTotalDepositsCredits}
      validationRegex={/^[0-9]*\.?[0-9]*$/}
      errorMessage={"Only Numeric Values are Allowed"}
      />
      </Grid>
      </Box>

      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Cheques & Debits</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <CustomTextField
      value={totalChequesDebits}
      onChange={setTotalChequesDebits}
      validationRegex={/^[0-9]*\.?[0-9]*$/}
      errorMessage={"Only Numeric Values are Allowed"}
      />
      </Grid>
      </Box>

      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Account Fees</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <CustomTextField
      value={accountFees}
      onChange={setAccountFees}
      validationRegex={/^[0-9]*\.?[0-9]*$/}
      errorMessage={"Only Numeric Values are Allowed"}
      />
      </Grid>
      </Box>


      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Closing Balance On</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <TextField 
      size='small' 
      variant="outlined"
      type='date'
      fullWidth
      onChange={(e)=>setClosingBalanceOn(e.target.value)}
      value={closingBalanceOn}
      />
      </Grid>
      </Box>

      <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <Typography variant='subtitle1' fontWeight={600}>Closing Balance Amount</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
      <CustomTextField
      value={closingBalance}
      onChange={setClosingBalance}
      validationRegex={/^[0-9]*\.?[0-9]*$/}
      errorMessage={"Only Numeric Values are Allowed"}
      />
      </Grid>
      </Box>

      <Box>
      <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center'>
      <Button onClick={onSubmit} variant='contained' fullWidth   color='warning'>Submit</Button> 
      </Grid>
      </Box>

      </Stack>

      </Grid>
      </Box>
      </Stack>

      <Stack spacing={1}>
      <Box sx={{cursor:'pointer'}} p={2} textAlign='left'>
      <Typography variant='h5' sx={{color:'#faa634' , cursor:'pointer'}}>Bank Statement Auto Updation</Typography>
      </Box>

      <Container>
      <Grid container spacing={4}>
      <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' gap={3} py={4}>
      <Box sx={{ textAlign: 'center' }}>
      <label htmlFor="bomfile">
      <input onChange={importStatment} className='d-none' id="bomfile" type="file" />
      <Button
      startIcon={<InsertDriveFileIcon />}
      variant="contained"
      color={OutBoundfile !== null ? 'secondary' : 'primary'}
      component="span">
      {buttonName}
      </Button>
      </label>
      </Box>
      </Grid>
      </Grid>
      </Container>
      </Stack>

      <Stack spacing={1}>
    <Box display='flex' justifyContent='space-between'  pb={1}>
    <Box sx={{cursor:'pointer'}} p={2} textAlign='left'>
    <Typography variant='h5' sx={{color:'#faa634' , cursor:'pointer'}}>Bank Account Statement</Typography>
    </Box>
    <Box p={2}>
     <ExportBankStmnt csvData={data} fileName='Bank Statement' />
    </Box>
    </Box>


    <Grid container spacing={1}>
    <TableContainer sx={{ border: '1px solid silver', boxShadow: 4 }} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
          <TableRow>
          <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }}>
          S.No
          </TableCell>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
              {key === "openingBalanceOn" && (
              <Box>
              <Filter search={openingBalanceOnSearch} setSearch={setopeningBalanceOnSearch}  setPage={setPage} />
              </Box>
              )
              }
              {key === "closingBalanceOn" && (
              <Box>
              <Filter search={closingBalanceOnSearch} setSearch={setclosingBalanceOnSearch}  setPage={setPage} />
              </Box>
              )
              }
            </Box>
             </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {FilterBankTwoData(data, openingBalanceOnSearch, closingBalanceOnSearch).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                     {index + 1}
                </TableCell>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={FilterBankTwoData(data, openingBalanceOnSearch, closingBalanceOnSearch).length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>      
         </Stack>
          </Stack>
      </Box>
  )
}
