import React, { useRef } from 'react'
import { Box, Typography, Container, Grid, Rating, Card, CardMedia, Link, CardContent,  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Breadcrumbs, CardActionArea, Dialog, DialogContent,DialogActions, DialogTitle, DialogContentText, Stack, TextField, List, Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton, InputAdornment, Input, Divider } from '@mui/material'
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AddBox, EmailOutlined } from '@mui/icons-material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { add_balance_sheet, get_balance_sheet, get_inventory_for_cash_flow } from '../../../Services/APIService';
import CustomTextField from '../../../Components/CustomTextField';
import SnackBar from '../../../Components/AdminDashBoardComps/Snackbar';


export default function BalanceSheetForm() {

  const [balanceSheetPeriod, setBalanceSheetPeriod] = useState(null);
  const [cash, setCash] = useState('');
  const [inventory, setInventory] = useState('');
  const [otherCurrentAssets, setOtherCurrentAssets] = useState('');
  const [totalCurrentAssets, setTotalCurrentAssets] = useState('');
  const [longTermAssets, setLongTermAssets] = useState('');
  const [accumulatedDepreciation, setAccumulatedDepreciation] = useState('');
  const [totalLongTermAssets, setTotalLongTermAssets] = useState('');
  const [totalAssets, setTotalAssets] = useState('');
  const [currentLiabilities, setCurrentLiabilities] = useState('');
  const [accountsPayable, setAccountsPayable] = useState('');
  const [currentBorrowing, setCurrentBorrowing] = useState('');
  const [otherCurrentLiabilities, setOtherCurrentLiabilities] = useState('');
  const [subTotalCurrentLiabilities, setSubTotalCurrentLiabilities] = useState('');
  const [longTermLiabilities, setLongTermLiabilities] = useState('');
  const [totalLiabilities, setTotalLiabilities] = useState('');
  const [paidInCapital, setPaidInCapital] = useState('');
  const [retainedEarnings, setRetainedEarnings] = useState('');
  const [earnings, setEarnings] = useState('');
  const [totalCapital, setTotalCapital] = useState('');
  const [equity, setEquity] = useState('');
  const datePickerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [financialYear, setFinancialYear] = useState(null);
  const handleDateChange = (date) => {
    setFinancialYear(date);
    const year = date.year(); 
    const nextYear = year + 1;
    const financialYearString = `${year}-${nextYear.toString()}`;
    setBalanceSheetPeriod(financialYearString);
  };
  


  const getallData = () =>{
    if(balanceSheetPeriod !== null && balanceSheetPeriod !== '' && balanceSheetPeriod !== undefined){
    const sendData = new FormData()
    sendData.append('balanceSheetPeriod' , balanceSheetPeriod)
    axios({
        method: "POST",
        url: get_inventory_for_cash_flow,
        data:sendData
    }).then(res => {
        if (!res.data.error) {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
            setInventory(res.data.data.inventory)
            setCash(res.data.data.cash)
            setOtherCurrentAssets(res.data.data.otherAssets)
        } else {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        }
    }).catch(err => {
        console.log(err)
    });
  }
    }

    useEffect(()=>{
      getallData();
    },[balanceSheetPeriod])

    console.log(cash);

    useEffect(()=>{
      const parsedCash = parseFloat(cash) || 0;
      const parsedInventory = parseFloat(inventory) || 0;
      const parsedOthercuurentasset = parseFloat(otherCurrentAssets) || 0;
      setTotalCurrentAssets((parsedCash + parsedInventory + parsedOthercuurentasset).toFixed(2));
    },[cash, inventory, otherCurrentAssets])


    useEffect(()=>{
      const parsedlongtermasset = parseFloat(longTermAssets) || 0;
      const parsedaccumulated = parseFloat(accumulatedDepreciation) || 0;
      setTotalLongTermAssets((parsedlongtermasset + parsedaccumulated).toFixed(2));
    },[longTermAssets, accumulatedDepreciation])

    useEffect(()=>{
      const parsedtotalcurrent = parseFloat(totalCurrentAssets) || 0;
      const parsedtotallongterm = parseFloat(totalLongTermAssets) || 0;
      setTotalAssets((parsedtotalcurrent + parsedtotallongterm).toFixed(2));
    },[totalCurrentAssets, totalLongTermAssets])


    useEffect(()=>{
      const parsedcurrentLiab = parseFloat(currentLiabilities) || 0;
      const parsedtotalAccount = parseFloat(accountsPayable) || 0;
      const parsedtotalcurrentborrow = parseFloat(currentBorrowing) || 0;
      const parsedtotalothercurrent = parseFloat(otherCurrentLiabilities) || 0;
      setSubTotalCurrentLiabilities((parsedcurrentLiab + parsedtotalAccount +  parsedtotalcurrentborrow + parsedtotalothercurrent ).toFixed(2));
    },[currentLiabilities, accountsPayable ,currentBorrowing , otherCurrentLiabilities ])


    useEffect(()=>{
      const parsedtotalcurrent = parseFloat(subTotalCurrentLiabilities) || 0;
      const parsedtotallongterm = parseFloat(longTermLiabilities) || 0;
      setTotalLiabilities((parsedtotalcurrent + parsedtotallongterm).toFixed(2));
    },[subTotalCurrentLiabilities, longTermLiabilities])


    useEffect(()=>{
      const parsedpaidcaptial = parseFloat(paidInCapital) || 0;
      const parsedretained = parseFloat(retainedEarnings) || 0;
      const parsedearnings = parseFloat(earnings) || 0;
      setTotalCapital((parsedpaidcaptial + parsedretained + parsedearnings).toFixed(2));
    },[paidInCapital, retainedEarnings, earnings])


    useEffect(()=>{
      const parsedtotalAssets = parseFloat(totalAssets) || 0;
      const parsedtotalLiab = parseFloat(totalLiabilities) || 0;
      const parsedtotalcaptial = parseFloat(totalCapital) || 0;
      setEquity((parsedtotalAssets +  parsedtotalcaptial - parsedtotalLiab).toFixed(2));
    },[totalAssets, totalLiabilities ,totalCapital ])

    const onSubmit = () => {
      if(balanceSheetPeriod !== null && balanceSheetPeriod !== '' && balanceSheetPeriod !== undefined){
      const originalDate = new Date(balanceSheetPeriod);
      const formattedDate = originalDate.getFullYear().toString();
      const serverData = new FormData()
      serverData.append('balanceSheetPeriod', balanceSheetPeriod);
      serverData.append('cash', cash);
      serverData.append('inventory', inventory);
      serverData.append('otherCurrentAssets', otherCurrentAssets);
      serverData.append('totalCurrentAssets', totalCurrentAssets);
      serverData.append('longTermAssets', longTermAssets);
      serverData.append('accumulatedDepreciation', accumulatedDepreciation);
      serverData.append('totalLongTermAssets', totalLongTermAssets);
      serverData.append('totalAssets', totalAssets);
      serverData.append('currentLiabilities', currentLiabilities);
      serverData.append('accountsPayable', accountsPayable);
      serverData.append('currentBorrowing', currentBorrowing);
      serverData.append('otherCurrentLiabilities', otherCurrentLiabilities);
      serverData.append('subTotalCurrentLiabilities', subTotalCurrentLiabilities);
      serverData.append('longTermLiabilities', longTermLiabilities);
      serverData.append('totalLiabilities', totalLiabilities);
      serverData.append('paidInCapital', paidInCapital);
      serverData.append('retainedEarnings', retainedEarnings);
      serverData.append('earnings', earnings);
      serverData.append('totalCapital', totalCapital);
      serverData.append('equity', equity);
      axios({
          method: "POST",
          url:add_balance_sheet,
          data: serverData,
      }).then(res => {
          if (!res.data.error) {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
          setBalanceSheetPeriod(null)
          setCash('')
          setInventory('')
          setOtherCurrentAssets('')
          setTotalCurrentAssets('')
          setLongTermAssets('')
          setAccumulatedDepreciation('')
          setTotalLongTermAssets('')
          setTotalAssets('')
          setCurrentLiabilities('')
          setAccountsPayable('')
          setCurrentBorrowing('')
          setOtherCurrentLiabilities('')
          setSubTotalCurrentLiabilities('')
          setLongTermLiabilities('')
          setTotalLiabilities('')
          setPaidInCapital('')
          setRetainedEarnings('')
          setEarnings('')
          setTotalCapital('')
          setEquity('')
          setFinancialYear(null)
          datePickerRef.current.value = null;
          } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(false)
          setColor(false)
          }
      }).catch(err => {
          console.log(err)
      });
    }
    else{
      setMessage('Choose a Period');
      setOpen(true)
      setStatus(false)
      setColor(false) 
    }
  }

  return (
      <Box p={1}>

    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Box component={Card} p={3} boxShadow={4}>
    <Grid container>
    <Stack spacing={4} textAlign='left'>

    <Box pt={1} pb={1}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'column', xs:'column'}} gap={1}>
    <DatePicker
    views={['year']}
    label="Select Year"
    openTo='Year'
    value={financialYear}
    onChange={handleDateChange}
    renderInput={(params) => <TextField {...params} helperText={null} />}
    />
            <TextField
          id="financial-year"
          label="Financial Year"
          value={balanceSheetPeriod}
          inputRef={datePickerRef}
          sx={{mx:2}}
          InputProps={{
            readOnly: true,
             // Disable editing of the input field
          }}
          InputLabelProps={{
            shrink:true
          }}
        />
       </Box>
    </LocalizationProvider>
    </Box>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>Assets</Typography>
    </Box>
    <Box textAlign='left'>
    <Typography variant='subtitle1' fontWeight={600}>(A) Current Assets</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1A) Cash</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={cash}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2A) Inventory</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={inventory}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3A) Other Current Assets</Typography>
    <Typography variant='caption'>(Office Equipments/Supplies)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={otherCurrentAssets}

   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(4A) Total Current Assets</Typography>
    <Typography variant='caption'>(1A + 2A + 3A)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={totalCurrentAssets}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(5A) Long-term Assets</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={longTermAssets}
    onChange={setLongTermAssets}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(6A) Accumulated Depreciation</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={accumulatedDepreciation}
    onChange={setAccumulatedDepreciation}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(7A) Total Long-term Assets</Typography>
    <Typography variant='caption'>(5A + 6A)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalLongTermAssets}
    variant="outlined"
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(8A) Total Assets</Typography>
    <Typography variant='caption'>(4A + 7A)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalAssets}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(B) Liabilities and Capital</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1B) Current Liabilities</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={currentLiabilities}
    onChange={setCurrentLiabilities}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2B) Accounts Payable</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
         <CustomTextField
    value={accountsPayable}
    onChange={setAccountsPayable}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3B) Current Borrowing</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={currentBorrowing}
    onChange={setCurrentBorrowing}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(4B) Other Current Liabilities</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={otherCurrentLiabilities}
    onChange={setOtherCurrentLiabilities}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(5B) Subtotal Current Liabilities</Typography>
    <Typography variant='caption'>(1B + 2B + 3B + 4B)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
     value={subTotalCurrentLiabilities}
    variant="outlined"
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(6B) Long-term Liabilities</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={longTermLiabilities}
    onChange={setLongTermLiabilities}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(7B) Total Liabilities</Typography>
    <Typography variant='caption'>(5B + 6B)</Typography>
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalLiabilities}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1C) Paid-in Capital</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={paidInCapital}
    onChange={setPaidInCapital}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2C) Retained Earnings</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={retainedEarnings}
    onChange={setRetainedEarnings}
   validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3C) Earnings</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
  <CustomTextField
  value={earnings}
  onChange={setEarnings}
  validationRegex={/^[0-9]*\.?[0-9]*$/}
  errorMessage={"Only Numeric Values are Allowed"}
  />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(4C) Total Capital</Typography>
    <Typography variant='caption'>(1C + 2C + 3C)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={totalCapital}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>Equity</Typography>
    <Typography variant='caption'>(8A + 4C - 7B)</Typography>
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
     value={equity}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>


    <Box>
    <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center'>
    <Button onClick={onSubmit} variant='contained' fullWidth   color='warning'>Submit</Button> 
    </Grid>
    </Box>

    </Stack>
    </Grid>
    </Box>


      </Box>
  )
}
