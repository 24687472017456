import { Box, Grid, Card ,Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { get_cash_flow } from '../../../Services/APIService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ExportExcel } from '../ExportExcel';


export default function AllCashFLow() {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [cashFlowPeriod , setcashFlowPeriod] = useState(null);
  const [financialYear, setFinancialYear] = useState('');

  const textFieldRef = useRef();

  const handleDateChange = (date) => {
    setFinancialYear(date);
    const year = date.year(); 
    const nextYear = year + 1;
    const financialYearString = `${year}-${nextYear.toString()}`;
    setcashFlowPeriod(financialYearString);
    getallcashflow();
  };

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear(); // Corrected to get the full year
    const nextYear = year + 1;
    const financialYearString = `${year}-${nextYear.toString()}`;
    
    setFinancialYear(date);
    setcashFlowPeriod(financialYearString);
}, []);


  const getallcashflow = () =>{
    const sendData = new FormData()
    sendData.append('cashFlowPeriod' , cashFlowPeriod)
    axios({
        method: "POST",
        url: get_cash_flow,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            setData([]);
        } else {
            setOpen(true)
            setMessage(res.data.message)
             setData(res.data.data);
            setStatus(true)
            setColor(true)
        }
    }).catch(err => {
        console.log(err)
    });
    }

        useEffect(() => {
          getallcashflow();
        }, [cashFlowPeriod])


  return (
    <Box p={2}>

<Box pt={1} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'column', xs:'column'}} gap={1}>
    <DatePicker
    views={['year']}
    label="Select Year"
    openTo='Year'
    onChange={handleDateChange}
    renderInput={(params) => <TextField {...params} helperText={null} />}
    />
            <TextField
          id="financial-year"
          label="Financial Year"
          value={cashFlowPeriod}
          sx={{mx:2}}
          disabled
          InputProps={{
            readOnly: true, // Disable editing of the input field
          }}
          InputLabelProps={{
            shrink:true
          }}
        />
        </Box>
    </LocalizationProvider>
    
      {
      data && data.length !== 0 ? 
      <ExportExcel data={data} />
      :
      null
      }
  
    </Box>

<TableContainer component={Paper} sx={{ boxShadow: 4 }}>
  <Table id='cashTable'>
    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
      <TableRow sx={{ '& > *': { width: '2%' } }}>
        <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }}>
          Items
        </TableCell>
        {data &&
          Object.keys(data).map((month) => (
            <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }} key={month}>
              {month}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>

    <TableBody>
      {data &&
        Object.keys(data[Object.keys(data)[0]] || {})
          .filter((item) => item !== 'cashFlowId' && item !== 'cashFlowPeriod') // Exclude specific keys
          .map((item) => (
            <TableRow key={item}>
          <TableCell align='left' sx={{ fontWeight: 600 }}>
          {item.split(/(?=[A-Z])/).join(' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
          </TableCell>
              {data &&
                Object.keys(data).map((month) => (
                  <TableCell align='center' sx={{ fontWeight: 500, fontSize: 14 }} key={month}>
                    {data[month] && data[month][item] !== undefined && data[month][item] !== null
                      ? typeof data[month][item] === 'number'
                        ? Number(data[month][item]).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
                        : data[month][item]
                      : ''}
                  </TableCell>
                ))}
            </TableRow>
          ))}
    </TableBody>
  </Table>
</TableContainer>




    </Box>
  )
}
