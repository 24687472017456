import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const ExportExcel = ({ data }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Convert data format for the sheet
    const sheetData = [];

    // Prepare table headers
    const headers = ['Items', ...Object.keys(data)];

    // Push headers as the first row
    sheetData.push(headers);

    // Iterate over each item in the data object
    for (const item in data[Object.keys(data)[0]]) {
        if (item !== 'incomeId' && item !== 'incomePeriod' && item !== 'cashFlowId' && item !== 'cashFlowPeriod' && item !== 'balanceSheetId' && item !== 'balanceSheetPeriod') { // Exclude specific keys
            const rowData = [item.split(/(?=[A-Z])/).join(' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())];
            
            // Iterate over each month in the data object
            for (const month in data) {
                rowData.push(
                    data[month][item] !== undefined && data[month][item] !== null
                        ? typeof data[month][item] === 'number'
                            ? Number(data[month][item]).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
                            : data[month][item]
                        : ''
                );
            }
            sheetData.push(rowData);
        }
    }

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    // Convert worksheet to workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Table Data');

    // Convert workbook to Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });

    // Function to save the Excel file
    const saveExcelFile = () => {
        FileSaver.saveAs(blob, 'Table Data' + fileExtension);
    };

    return (
        <Button variant="contained" onClick={saveExcelFile} startIcon={<ExitToAppIcon />}>
            Export
        </Button>
    );
};
