import { Box, Typography, Card, Grid, TextField, Button, Autocomplete, Stack, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useState } from 'react';
import { CategoryImageAPI, add_main_category, getallcategory, update_main_category } from '../../Services/APIService';
import axios from 'axios';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AllCategoryTable, UpdateCategoryTable } from '../../Variables/Variables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteDialog from './DeleteDialog';


export default function UpdateCategory() {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [newCategory, setNewCategory] = useState('');
    const [newCategoryImage, setNewCategoryImage] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [showUpdate , setShowUpdate] =  useState(false);
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [newMainImageURLs, setNewMainImageURLs] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogData, setOpenDialogData] = useState(false);

    const handleMainImage = (e) => {
        if (!e.target.files[0]) {
            // User cancelled file selection, revert to the old main image
            return;
        }
        setNewCategoryImage(e.target.files[0]);
        const temporaryURL = URL.createObjectURL(e.target.files[0]);
        setNewMainImageURLs(temporaryURL);
    };



    const getallcategoryAPI = () =>{
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
        }

            useEffect(() => {
              getallcategoryAPI();
            }, [])

        const openUpdate = (categoryId) => {
        setEditCategoryId(categoryId);
        };

        const CancelUpdate = () => {
        setEditCategoryId(null);
        getallcategoryAPI();
        };




    const handleClick = (categoryId) => {
        const serverData = new FormData()
        serverData.append('mainCategoryName', newCategory)
        serverData.append('categoryImage', newCategoryImage)
        serverData.append('mainCategoryId', categoryId)
        axios({
            method: "POST",
            url: update_main_category,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setShowUpdate(false);
                setEditCategoryId(null);
                getallcategoryAPI();
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleOpen = (i) =>{
        setOpenDialog(true)
        setOpenDialogData(i)
    }

    return (
        <Box p={3}>

            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <DeleteDialog i={openDialogData} setOpenDialog={setOpenDialog} openDialog={openDialog} getallcategoryAPI={getallcategoryAPI}/>
            <Box py={3}>
                <Heading title={'Update Category'} />
            </Box>

            <Box py={3}>
                <Grid container>
                    <TableContainer sx={{ border: '1px solid silver', boxShadow: 4 }} >

                        <Table>
                            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                    {
                                        UpdateCategoryTable.map((i, index) => {
                                            return (
                                                <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Box sx={{ my: 'auto' }}>
                                                            {i.title}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>


                            <TableBody>

                                {
                                categoryList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                const isEditing = i.categoryId === editCategoryId;
                                return (
                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.categoryId}</TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                {
                                !showUpdate && !isEditing ?
                                <img style={{ width: 80, height: 80 }} src={`${CategoryImageAPI}${i.categoryImage}`} alt='Image' />
                                :
                                <Box display='flex' flexDirection='row' gap={2}>   
                                {newMainImageURLs ? (
                                <img style={{ width: 100, height: 100 }} alt="mainImage" src={newMainImageURLs} />
                                ) : (    
                                <img style={{ width: 100, height: 100 }} alt="mainImage" src={`${CategoryImageAPI}${i.categoryImage}`}  />
                                )}
                                <IconButton
                                aria-label="upload"
                                component="label"
                                sx={{ ':hover': { backgroundColor: 'transparent' } }}
                                >
                                <EditIcon color='#EF4765'/>
                                <TextField
                                hidden
                                id="profilePicUpload"
                                type="file"
                                onChange={handleMainImage}
                                />
                                </IconButton>
                                </Box>
                                }
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                {
                                !showUpdate && !isEditing ? 
                                <Typography>{i.categoriesName}</Typography>
                                :
                                <TextField
                                id="outlined-start-adornment"
                                size="small"
                                type='text'
                                label={i.categoriesName}
                                sx={{ width: '20ch' }}
                                disabled={!showUpdate ? false : true}
                                onChange={(e)=>setNewCategory(e.target.value)}
                                />
                                }  
                                </TableCell>
                                <TableCell>
                                <Box display='flex' flexDirection='row' gap={2}>
                                {
                                !showUpdate && !isEditing ? 
                                <>
                                <Button variant="contained" size='small' fullWidth onClick={() => openUpdate(i.categoryId)}>EDIT</Button>
                                <Button variant="contained" size='small' fullWidth color='error' onClick={()=>HandleOpen(i)}>DELETE</Button>
                                 
                                </>
                                :
                                <>
                                <Button variant="contained" size='small' fullWidth onClick={()=>handleClick(i.categoryId)}>UPDATE</Button>
                                <Button variant="contained" size='small' fullWidth color='error' onClick={CancelUpdate}>CANCEL</Button>
                                </>
                                }                                                    
                                </Box>
                                </TableCell>
                                </TableRow>
                                )
                                })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={categoryList.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>
        </Box>
    )
}
