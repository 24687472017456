import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Heading from "../../Components/Heading/Heading";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Divider from "@mui/material/Divider";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  add_channel_sale_order,
  add_purchase,
  add_whole_sale_order,
  get_all_product_name,
  get_brand_products,
  get_color_by_product_id,
  get_product_and_cost,
  get_provinces,
  get_vendor,
  gettax,
} from "../../Services/APIService";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SnackBar from "../../Components/AdminDashBoardComps/Snackbar";
import CustomTextField from "../../Components/CustomTextField";

export default function NewWholeSale() {
  const [tableRows, setTableRows] = useState([{}]); // Initial row
  const [supplierName, setSupplierName] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState("");
  const [totalWithoutVat, setTotalWithoutVat] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [discountAmount, setDiscountAmount] = useState("");
  const [invoiceVat, setInvoiceVat] = useState(0);
  const [netTotal, setNetTotal] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [productList, setProductList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [SavedColorQty, setSavedColorQty] = useState([]);
  const [SavedColor, setSavedColor] = useState("");
  const [orderedColor, setOrderedColor] = useState("");
  const [SavedQty, setSavedQty] = useState("");
  const [currentRowIndex, setCurrentRowIndex] = useState("");
  const [overallSubtotal, setOverallSubtotal] = useState(0);
  const [VendorList, setVendorList] = useState([]);
  const [openDialogs, setOpenDialogs] = useState(tableRows.map(() => false));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [customerProvince, setcustomerProvince] = useState(null);
  const [taxCost, setTaxcost] = useState("");
  const [tax, setTax] = useState("");
  const [GST, setGST] = useState("");
  const [PST, setPST] = useState("");
  const [GSTPercent, setGSTPercent] = useState("");
  const [PSTPercent, setPSTPercent] = useState("");
  const [orderValue, setOrderValue] = useState("");
  const [Taxinfo, setTaxinfo] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [DueFor, setDueFor] = useState(null);
  const [manualProductName, setManualProductName] = useState("");
  const [billBox, setBillBox] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyMail, setCompanyMail] = useState("");
  const [companyStreet, setCompanyStreet] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyProvince, setCompanyProvince] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerMail, setCustomerMail] = useState("");
  const [customerStreet, setCustomerStreet] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");
  const [HSTProvince, setHSTProvince] = useState(false);

  const [errorMsg1, setErrorMsg1] = useState("");
  const [Error1, setError1] = useState(false);

  const [errorMsg2, setErrorMsg2] = useState("");
  const [Error2, setError2] = useState(false);

  const [errorMsg3, setErrorMsg3] = useState("");
  const [Error3, setError3] = useState(false);

  const handleEmailChange = (event) => {
    setCompanyMail(event.target.value);
  };

  const handleCustomerEmailChange = (event) => {
    setCustomerMail(event.target.value);
  };

  const getallVendorAPI = () => {
    axios({
      method: "POST",
      url: get_vendor,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setVendorList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getallVendorAPI();
  }, []);

  const handleAddRow = () => {
    const newRow = {
      productId: "",
      productName: "",
      productCost: "",
      overAllQty: "",
      savedColorQty: [],
      subtotal: "",
    };
    setTableRows((prevRows) => [...prevRows, newRow]);
  };

  const handleDeleteRow = (index) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: get_brand_products,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setProductList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Productoptions = productList.map((i) => {
    const obj = {
      label: i.productName,
      id: i.productId,
    };
    return obj;
  });

  const getProductColor = (product, index) => {
    if (product.id !== "" || product.id !== null) {
      const sendData = new FormData();
      sendData.append("productId", product.id);
      axios({
        method: "POST",
        url: get_color_by_product_id,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setTableRows((prevRows) => {
              const updatedRows = [...prevRows];
              updatedRows[index].ColorQuantity = res.data.data;
              return updatedRows;
            });
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleProductChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (value) {
        updatedRows[index].productName = value.label;
        updatedRows[index].productColor = null;
      } else {
        updatedRows[index].productName = "";
        updatedRows[index].productCost = "";
      }
      return updatedRows;
    });

    // if (value) {
    //     getProductCost(value, index);
    //     getProductColor(value, index);
    // }
  };

  const handleManualProductChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (value) {
        updatedRows[index].productName = value;
        updatedRows[index].productColor = null;
      } else {
        updatedRows[index].productName = "";
        updatedRows[index].productCost = "";
      }
      return updatedRows;
    });

    // if (value) {
    //     getProductCost(value, index);
    //     getProductColor(value, index);
    // }
  };

  const calculateSubtotal = (cost, quantity) => {
    if (
      cost !== null &&
      quantity !== null &&
      cost !== "" &&
      quantity !== "" &&
      cost !== undefined &&
      quantity !== undefined
    ) {
      return parseFloat(cost * quantity).toFixed(2);
    } else {
      return 0;
    }
  };

  const handleProductCostChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].productCost = value;
      updatedRows[index].subtotal = calculateSubtotal(
        value,
        updatedRows[index].overAllQty
      );
      return updatedRows;
    });
  };

  const handleOverAllQtyChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].overAllQty = value;
      updatedRows[index].subtotal = calculateSubtotal(
        updatedRows[index].productCost,
        value
      );
      return updatedRows;
    });
  };

  const handleOverAllColorChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].productColor =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return updatedRows;
    });
  };

  const handleSubtotalChange = (index, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].subtotal = value;
      return updatedRows;
    });
  };

  useEffect(() => {
    const subtotal = tableRows.reduce(
      (total, row) => total + (row.subtotal || 0),
      0
    ); // Handle undefined values
    const parsedSubtotal = parseFloat(subtotal);
    if (!isNaN(parsedSubtotal)) {
      // Check for valid numbers
      setOverallSubtotal(parsedSubtotal.toFixed(2));
      setTotalWithoutVat(parsedSubtotal.toFixed(2));
      setNetTotal(parsedSubtotal.toFixed(2));
    }
  }, [tableRows]);

  const handleDiscountTypeChange = (event, value) => {
    setDiscountType(value);
    if (value === "Fixed") {
      setDiscountAmount(discount);
      const netTotalAmount = parseFloat(overallSubtotal - discount);
      setNetTotal(netTotalAmount.toFixed(2));
    } else if (value === "Percentage") {
      const discountPercent = parseFloat(discount);
      const discountAmountValue = (discountPercent / 100) * overallSubtotal;
      const netTotalAmount = overallSubtotal - discountAmountValue;
      setDiscountAmount(discountAmountValue.toFixed(2));
      setNetTotal(netTotalAmount);
    } else {
      setNetTotal(overallSubtotal);
      setDiscountAmount("");
    }
  };

  const handlePaidAmount = (e) => {
    setPaidAmount(e.target.value);
    if (e.target.value !== "" && e.target.value !== null) {
      const duePayment = totalAmountToPay - e.target.value;
      setDueAmount(duePayment.toFixed(2));
    } else {
      setDueAmount(netTotal);
    }
  };

  const product = {};
  const colorQty = {};

  tableRows &&
    tableRows.forEach((item, index) => {
      const productItem = {
        productName: item.productName,
        productPrice: item.productCost,
        orderedQty: item.overAllQty,
        productColor: item.productColor,
      };
      product[index] = productItem;
    });

  const productArray = Object.values(product);
  const colorQtyArray = Object.values(colorQty);

  useEffect(() => {
    axios({
      method: "GET",
      url: get_provinces,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setOpen(true);
        } else {
          setProvinceList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const options = provinceList.map((i) => {
    const obj = {
      label: i.ProvinceName,
      code: i.provinceCode,
      id: i.provinceId,
    };
    return obj;
  });

  const HSTProvincesList = [
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
  ];

  useEffect(() => {
    if (
      customerProvince !== null &&
      customerProvince !== "" &&
      netTotal !== null &&
      netTotal !== ""
    ) {
      const isHSTProvince = HSTProvincesList?.some(
        (i) => i === customerProvince?.label
      );
      const serverData = new FormData();
      serverData.append("province", customerProvince?.label);
      serverData.append("total", netTotal);
      axios({
        method: "POST",
        url: gettax,
        data: serverData,
      })
        .then((res) => {
          if (!res.data.error) {
            setTaxcost(res.data.tax_details.total_tax_amount);
            setTax(res.data.tax_details.total_tax);
            setGST(
              isHSTProvince
                ? res.data.tax_details.hst_amount
                : res.data.tax_details.gst_amount
            );
            setPST(res.data.tax_details.pst_amount);
            setGSTPercent(
              isHSTProvince
                ? res.data.tax_details.hst
                : res.data.tax_details.gst
            );
            setPSTPercent(res.data.tax_details.pst);
            setOrderValue(res.data.tax_details.total_amount);
          } else {
            setOpen(true);
            setMessage(res.data.message);
            setStatus(false);
            setColor(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [customerProvince, netTotal]);

  const [totalAmountToPay, setTotalAmountToPay] = useState("");

  useEffect(() => {
    const isValidNumber = (value) => !isNaN(parseFloat(value));

    if (isValidNumber(netTotal) && isValidNumber(taxCost)) {
      const calculatedTotal = parseFloat(netTotal) + parseFloat(taxCost);

      setTotalAmountToPay(calculatedTotal.toString());
      setPaidAmount("");
      setDueAmount("");
    } else {
      setTotalAmountToPay("");
      setPaidAmount("");
      setDueAmount("");
    }
  }, [netTotal, customerProvince, taxCost]);

  const validateInput = () => {
    if (companyMail !== "") {
      if (/\S+@\S+\.\S+/.test(companyMail)) {
        setErrorMsg3("");
        setError3(false);
      } else {
        setError3(true);
        setErrorMsg3("Invalid Email Address");
      }
    } else {
      setError3(true);
      setErrorMsg3("Email Address Cannot Be Empty");
    }
  };

  const CustomervalidateInput = () => {
    if (customerMail !== "") {
      if (/\S+@\S+\.\S+/.test(customerMail)) {
        setErrorMsg3("");
        setError3(false);
      } else {
        setError3(true);
        setErrorMsg3("Invalid Email Address");
      }
    } else {
      setError3(true);
      setErrorMsg3("Email Address Cannot Be Empty");
    }
  };

  const handleUpdateShipping = (e) => {
    if (e.target.checked) {
      setBillBox(true);
      setCustomerName(companyName);
      setCustomerMobile(companyMobile);
      setCustomerMail(companyMail);
      setCustomerStreet(companyStreet);
      setCustomerCity(companyCity);
      setCustomerCountry(companyCountry);
      setCustomerState(companyProvince);
      setCustomerPincode(companyPincode);
    } else {
      setBillBox(false);
      setCustomerName("");
      setCustomerMobile("");
      setCustomerMail("");
      setCustomerStreet("");
      setCustomerCity("");
      setCustomerCountry("");
      setCustomerState("");
      setCustomerPincode("");
    }
  };

  console.log(customerState?.label);

  const onSubmit = () => {
    if (
      companyName !== "" &&
      purchaseDate !== "" &&
      paidAmount !== "" &&
      taxCost !== "" &&
      paymentMethod !== ""
    ) {
      const serverData = new FormData();
      serverData.append("saleDate", purchaseDate);
      serverData.append("totalWithoutVat", totalWithoutVat);
      serverData.append("discount", discount);
      serverData.append("discountType", discountType);
      serverData.append("discountAmount", discountAmount);
      serverData.append("invoiceVat", invoiceVat);
      serverData.append("netTotal", netTotal);
      serverData.append("paymentMethod", paymentMethod);
      serverData.append("paidAmount", paidAmount);
      serverData.append("paymentTerms", paymentTerms);
      serverData.append("dueAmount", dueAmount);
      serverData.append("items", JSON.stringify(product));
      serverData.append("customerProvince", customerState?.label);
      serverData.append("taxCost", taxCost);
      serverData.append("dueFor", DueFor);
      serverData.append("companyName", companyName);
      serverData.append("companyMobile", companyMobile);
      serverData.append("companyMail", companyMail);
      serverData.append("companyStreet", companyStreet);
      serverData.append("companyCity", companyCity);
      serverData.append("companyProvince", companyProvince?.label);
      serverData.append("companyCountry", companyCountry);
      serverData.append("companyPincode", companyPincode);
      serverData.append("customerName", customerName);
      serverData.append("customerMobile", customerMobile);
      serverData.append("customerMail", customerMail);
      serverData.append("customerStreet", customerStreet);
      serverData.append("customerCity", customerCity);
      serverData.append("customerCountry", customerCountry);
      serverData.append("customerPincode", customerPincode);
      axios({
        method: "POST",
        url: add_whole_sale_order,
        data: serverData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setOpen(true);
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            setCustomerName("");
            setPhoneNumber("");
            setEmail("");
            setPurchaseDate("");
            setTotalWithoutVat("");
            setDiscount(0);
            setDiscountType(null);
            setDiscountAmount("");
            setInvoiceVat(0);
            setNetTotal("");
            setPaymentMethod("");
            setPaidAmount("");
            setDueAmount("");
            setProductList([]);
            setSavedColorQty([]);
            setSavedColor("");
            setSavedQty("");
            setCurrentRowIndex("");
            setOverallSubtotal(0);
            setTableRows([{}]);
            setcustomerProvince(null);
            setTaxinfo("");
            setPaymentTerms("");
            setTax("");
            setOrderValue("");
            setTaxcost("");
            setDueFor(null);
            setCustomerName("");
            setCustomerMobile("");
            setCustomerMail("");
            setCustomerStreet("");
            setCustomerCity("");
            setCustomerCountry("");
            setCustomerState("");
            setCustomerPincode("");
            setCompanyName("");
            setCompanyMobile("");
            setCompanyMail("");
            setCompanyStreet("");
            setCompanyCity("");
            setCompanyCountry("");
            setCompanyProvince("");
            setCompanyPincode("");
            setManualProductName("");
            setBillBox(false);
            setGST("");
            setPST("");
            setGSTPercent("");
            setPSTPercent("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpen(true);
      setMessage("Fill all the Fields");
      setStatus(false);
      setColor(false);
    }
  };

  return (
    <Box p={2}>
      <Box p={3}>
        <Heading title={"Create New WholeSale"} />
      </Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />
      <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
        <Container>
          <Box mt={1} py={2}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} py={2}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box textAlign="left">
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="#616e80"
                        >
                          Billing Address
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyName}
                        label="Company Name"
                        value={companyName}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyMobile}
                        label="Company Phone"
                        value={companyMobile}
                        validationRegex={/^[0-9]+$/}
                        errorMessage={"Only Numeric Values are Allowed"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="email"
                        label="Company Email"
                        size="small"
                        variant="outlined"
                        helperText={errorMsg3}
                        error={Error3}
                        onBlur={validateInput}
                        value={companyMail}
                        onChange={handleEmailChange} //
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyStreet}
                        label="Company Street"
                        value={companyStreet}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyCity}
                        label="Company City"
                        value={companyCity}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        size="small"
                        onChange={(event, value) => setCompanyProvince(value)}
                        value={companyProvince}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Province"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyCountry}
                        label="Company Country"
                        value={companyCountry}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCompanyPincode}
                        label="Company PostCode"
                        value={companyPincode}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Only alphanumeric characters allowed"}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={billBox}
                            onChange={handleUpdateShipping}
                          />
                        }
                        label="Same As Billing"
                      />
                    </FormGroup>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box textAlign="left">
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="#616e80"
                        >
                          Shipping Address
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerName}
                        label="Customer Name"
                        value={customerName}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerMobile}
                        label="Customer Phone"
                        value={customerMobile}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Only Numeric Values are Allowed"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="email"
                        label="Customer Email"
                        size="small"
                        variant="outlined"
                        helperText={errorMsg2}
                        error={Error2}
                        onBlur={CustomervalidateInput}
                        value={customerMail}
                        onChange={handleCustomerEmailChange} //
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerStreet}
                        label="Customer Street"
                        value={customerStreet}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerCity}
                        label="Customer City"
                        value={customerCity}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        size="small"
                        onChange={(event, value) => setCustomerState(value)}
                        value={customerState}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer Province"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerCountry}
                        label="Customer Country"
                        value={customerCountry}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <CustomTextField
                        onChange={setCustomerPincode}
                        label="Customer PostCode"
                        value={customerPincode}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Only alphanumeric characters allowed"}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <TableContainer component={Card} boxShadow={0}>
                <Table bgcolor="#eff8f2">
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                        colSpan={4}
                      >
                        Product
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Unit Cost($)
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Ordered Qty
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Color
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Sub Total($)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          colSpan={4}
                          align="center"
                          sx={{ borderRight: "1px solid silver" }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={Productoptions}
                            value={row.productName || ""}
                            size="small"
                            sx={{ width: 400 }}
                            freeSolo
                            onChange={(event, value) =>
                              handleProductChange(index, value)
                            }
                            renderInput={(params) => (
                              <TextField
                                onChange={(event) =>
                                  handleManualProductChange(
                                    index,
                                    event.target.value
                                  )
                                }
                                value={manualProductName}
                                {...params}
                                label="Select a Product"
                              />
                            )}
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ borderRight: "1px solid silver" }}
                        >
                          <TextField
                            id="outlined-basic"
                            onChange={(event) => {
                              if (
                                /^[0-9]*\.?[0-9]*$/.test(event.target.value) &&
                                !/[+-]/.test(event.target.value)
                              ) {
                                handleProductCostChange(
                                  index,
                                  event.target.value
                                );
                              }
                            }}
                            value={row.productCost || ""}
                            size="small"
                            sx={{ width: 100 }}
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ borderRight: "1px solid silver" }}
                        >
                          <TextField
                            id="outlined-basic"
                            value={row.overAllQty || ""}
                            onChange={(event) => {
                              if (
                                /^[0-9]*(\.[0-9]+)?$/.test(
                                  event.target.value
                                ) &&
                                !/[+\-]/.test(event.target.value)
                              ) {
                                handleOverAllQtyChange(
                                  index,
                                  event.target.value
                                );
                              }
                            }}
                            size="small"
                            sx={{ width: 100 }}
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ borderRight: "1px solid silver" }}
                        >
                          <TextField
                            id="outlined-basic"
                            value={row?.productColor || ""}
                            onChange={(event) =>
                              handleOverAllColorChange(
                                index,
                                event.target.value
                              )
                            }
                            size="small"
                            sx={{ width: 150 }}
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell align="center" colSpan={4}>
                          <Box display="flex" gap={4} justifyContent="end">
                            <TextField
                              value={row.subtotal || ""}
                              onChange={(event) =>
                                handleSubtotalChange(index, event.target.value)
                              }
                              size="small"
                              variant="outlined"
                            />
                            {index > 0 ? (
                              <HighlightOffIcon
                                onClick={() => handleDeleteRow(index)}
                                sx={{ verticalAlign: "middle" }}
                                color="error"
                                fontSize="large"
                              />
                            ) : (
                              <AddBoxIcon
                                onClick={handleAddRow}
                                sx={{ verticalAlign: "middle" }}
                                color="primary"
                                fontSize="large"
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Date
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          type="date"
                          onChange={(e) => setPurchaseDate(e.target.value)}
                          value={purchaseDate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Total
                        <Typography component="span" variant="caption">
                          (Excluding Vat)
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={overallSubtotal}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Discount
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setDiscount(e.target.value)}
                          value={discount}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Discount Type
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={["Fixed", "Percentage"]}
                          size="small"
                          disabled={discount === 0 ? true : false}
                          onChange={handleDiscountTypeChange}
                          value={discountType}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Discount Amount
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={discountAmount}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Net Total
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={netTotal}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Province
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={options}
                          size="small"
                          onChange={(event, value) =>
                            setcustomerProvince(value)
                          }
                          value={customerProvince}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Tax (%)
                      </TableCell>
                      <TableCell align="center">
                        <Stack spacing={1.5} textAlign="left">
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={tax}
                          />
                          <Typography variant="subtitle2">
                            GST/HST:{" "}
                            <span style={{ fontWeight: 600 }}>
                              {GSTPercent}%
                            </span>
                          </Typography>
                          <Typography variant="subtitle2">
                            PST/QST:{" "}
                            <span style={{ fontWeight: 600 }}>
                              {PSTPercent}%
                            </span>
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Total Tax ($)
                      </TableCell>
                      <TableCell align="center">
                        <Stack spacing={1.5} textAlign="left">
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={taxCost}
                          />
                          <Typography variant="subtitle2">
                            GST/HST : {"$"}
                            <span style={{ fontWeight: 600 }}>{GST}</span>
                          </Typography>
                          <Typography variant="subtitle2">
                            PST/QST : {"$"}
                            <span style={{ fontWeight: 600 }}>{PST}</span>
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Total Order Amount ($)
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={totalAmountToPay}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Payment Method
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={["Cash", "Card", "Cheque", "Others"]}
                          size="small"
                          onChange={(event, value) => setPaymentMethod(value)}
                          value={paymentMethod}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Payment Terms
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setPaymentTerms(e.target.value)}
                          value={paymentTerms}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Paid ($)
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={handlePaidAmount}
                          value={paidAmount}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Due ($)
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={dueAmount}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell
                        align="right"
                        colSpan={6}
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Due For
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={["30 Days", "45 Days", "60 Days", "90 Days"]}
                          size="small"
                          onChange={(event, value) => setDueFor(value)}
                          value={DueFor}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell />
                      <TableCell align="right" colSpan={6}></TableCell>
                      <TableCell align="center">
                        <Button
                          color="warning"
                          variant="contained"
                          fullWidth
                          onClick={onSubmit}
                        >
                          Submit
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
