import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Heading from '../../Components/Heading/Heading';
import axios from 'axios';
import { Container, Grid, Card, Typography, Button, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import PaidIcon from '@mui/icons-material/Paid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import { get_placed_orders, get_Orders, BaseImageAPI, create_shipment, update_order_status, capture_payment, cancel_payment, get_all_confirmed_orders, sales_invoice, printing_label, online_sale_packing } from '../../Services/APIService';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import PrintIcon from '@mui/icons-material/Print';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import ETPaymentDialog from './ETPaymentDialog';
import { ToastContainer, toast } from "react-toastify";

export default function ViewUpdatedOrderDetail() {


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [OrdersList, setOrdersList] = useState([]);
    const [OrderBasic, setOrderBasic] = useState();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const { userId, orderId } = location.state;
    const [products, setProducts] = useState([]);
    const [EtransferDialog, setEtransferDialog] = useState(false);
    const [ETNumber, setETNumber] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        const sendData = new FormData();
        sendData.append('orderId', orderId);
        axios({
            method: "POST",
            url: get_all_confirmed_orders,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setOrdersList(res.data.data);
                setOrderBasic(res.data.data[0]);
                setProducts(res.data.products)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [orderId]);


    const OpenETDialog = () => {
       
        setEtransferDialog(true);
    }


    // Confirm Status
    const confirmOrder = () => {
        const sendData = new FormData()
        sendData.append('userId', userId)
        sendData.append('orderId', orderId)
        sendData.append('orderStatus', 'Order Confirmed')
        axios({
            method: "POST",
            url: update_order_status,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                successMsg("Order Confirmed !")
                if (OrderBasic?.paymentOption === 'Credit / Debit Card') {
                    capturePayment();
                }
                else {
                    createShipment();
                }
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const cancelOrder = () => {
        const sendData = new FormData()
        sendData.append('userId', userId)
        sendData.append('orderId', orderId)
        sendData.append('orderStatus', 'Order Cancelled')
        axios({
            method: "POST",
            url: update_order_status,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                successMsg(res.data.message)
                if (OrderBasic?.paymentOption === 'Credit / Debit Card') {
                    cancelPayment();
                }
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const capturePayment = () => {
        const sendData = new FormData()
        sendData.append("paymentIntentId", OrderBasic?.paymentIntentId)
        axios({
            method: "POST",
            url: capture_payment,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                successMsg(res.data.message);
                createShipment();
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const cancelPayment = () => {
        const sendData = new FormData()
        sendData.append("paymentIntentId", OrderBasic?.paymentIntentId)
        axios({
            method: "POST",
            url: cancel_payment,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                successMsg(res.data.message);
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        });
    }




    const transformedProducts = {};

    products?.forEach((product, index) => {
        // Convert weight from grams to pounds (1 gram = 0.00220462 pounds)
        const weightInLb = product.productWeight * 0.00220462;

        // Check if the weight is less than 1 lb and set it to 1 lb if so
        const adjustedWeightInLb = weightInLb < 1 ? "1" : weightInLb;

        transformedProducts[index] = {
            productWeight: adjustedWeightInLb, // Store weight in pounds, at least 1 lb
            productLength: product.productLength,
            productWidth: product.productWidth,
            productHeight: product.productHeight,
        };
    });

    const createShipment = () => {
        const currentDate = new Date(); // Get the current date
        currentDate.setDate(currentDate.getDate() + 2); // Add two days to the current date

        // Format the date as needed (e.g., yyyy-mm-dd)
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        const areacode = OrderBasic?.phoneNum.substring(0, 3);
        const phone = OrderBasic?.phoneNum.substring(3);
        const customerName = OrderBasic?.firstName + OrderBasic?.lastName;
        const sendData = new FormData()
        sendData.append('orderId', orderId)
        sendData.append('receiverName', customerName);
        sendData.append('receiverCompany', '')
        sendData.append('receiverDepartment', '')
        sendData.append('receiverStreetNumber', '')
        sendData.append('receiverStreetName', OrderBasic?.address)
        sendData.append('receiverStreetType', 'Street')
        sendData.append('receiverCity', OrderBasic?.city)
        sendData.append('receiverProvince', OrderBasic?.provinceCode)
        sendData.append('receiverCountry', OrderBasic?.country)
        sendData.append('receiverPostalCode', OrderBasic?.pincode)
        sendData.append('receiverCountryCode', OrderBasic?.countryCode)
        sendData.append('receiverAreaCode', areacode)
        sendData.append('receiverPhone', phone)
        sendData.append('shipmentDate', formattedDate);
        sendData.append('totalWeight', OrderBasic?.totalWeight)
        sendData.append('totalPieces', OrderBasic?.totalPieces)
        sendData.append('serviceID', OrderBasic?.shippingService)
        sendData.append('productData', JSON.stringify(transformedProducts))
        axios({
            method: "POST",
            url: create_shipment,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                successMsg("Shipment Created !")
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const successMsg = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 2000,
            closeOnClick: true,
        });
    };

    const errorMsg = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 2000,
            closeOnClick: true,
        });
    };


    const handlegenerateInvoice = () => {
        const sendData = new FormData()
        sendData.append('userId', userId)
        sendData.append('orderId', orderId)
        axios({
            method: 'POST',
            url: sales_invoice,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);
                    // Open the URL in a new tab
                    const newTab = window.open(res.data.data, '_blank');

                    // Check if the new tab has been successfully opened
                    if (newTab !== null) {
                        // Add a timeout to ensure the URL has loaded before printing
                        setTimeout(() => {
                            newTab.print(); // Print the contents of the new tab
                        }, 1000); // Adjust the timeout delay as needed
                    } else {
                        alert('Popup blocker prevented opening the new tab. Please allow popups.');
                    }
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }


    const handlegenerateLabel = () => {
        const sendData = new FormData()
        sendData.append('orderId', orderId)
        axios({
            method: 'POST',
            url: printing_label,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);
                    // Open the URL in a new tab
                    const newTab = window.open(res.data.data?.Document.DocumentDetails?.DocumentDetail?.URL, '_blank');

                    // Check if the new tab has been successfully opened
                    if (newTab !== null) {
                        // Add a timeout to ensure the URL has loaded before printing
                        setTimeout(() => {
                            newTab.print(); // Print the contents of the new tab
                        }, 1000); // Adjust the timeout delay as needed
                    } else {
                        alert('Popup blocker prevented opening the new tab. Please allow popups.');
                    }
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }


    const handlegeneratePackingList = () => {
        const sendData = new FormData()
        sendData.append('userId', userId)
        sendData.append('orderId', orderId)
        axios({
            method: 'POST',
            url: online_sale_packing,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);
                    // Open the URL in a new tab
                    const newTab = window.open(res.data.data, '_blank');

                    // Check if the new tab has been successfully opened
                    if (newTab !== null) {
                        // Add a timeout to ensure the URL has loaded before printing
                        setTimeout(() => {
                            newTab.print(); // Print the contents of the new tab
                        }, 1000); // Adjust the timeout delay as needed
                    } else {
                        alert('Popup blocker prevented opening the new tab. Please allow popups.');
                    }
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }


    return (
        <Box p={2}>

            <Box p={3}>
                <Heading title={'Order Details'} />
            </Box>

            <ETPaymentDialog
                errorMsg={errorMsg}
                TransactionNumber={OrderBasic?.transactionNum}
                confirmOrder={confirmOrder}
                ETNumber={ETNumber}
                setETNumber={setETNumber}
                EtransferDialog={EtransferDialog}
                setEtransferDialog={setEtransferDialog}
            />

            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box component={Card} sx={{ boxShadow: 2 }}>

                <Grid container p={2} spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#00bca4' py={4}>
                                <ContactEmergencyIcon sx={{ color: '#FFFFFF' }} />
                                <Typography fontWeight={600} color='#FFFFFF' variant='h6'>Customer</Typography>
                            </Box>
                            <Box p={2} textAlign='left'>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Name:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.firstName} {OrderBasic?.lastName} </Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Phone:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.phoneNum}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Email:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'> {OrderBasic?.email}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Address:</Typography>
                                    <Typography fontWeight={600} variant='body2'>{OrderBasic?.province}, {OrderBasic?.pincode}, {OrderBasic?.country} </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#65a6ff' sx={{ color: '#FFFFFF' }} py={4}>
                                <ReceiptIcon />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Order Info</Typography>
                            </Box>
                            <Box p={2} >
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Number:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.userOrderId}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Cost:  </Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.totalOrderCost}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Time:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.orderTime}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Status:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'> {OrderBasic?.orderStatus} </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#984dff' py={4}>
                                <PaidIcon sx={{ color: '#FFFFFF' }} />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Payment & Shipment</Typography>
                            </Box>
                            <Box p={2}>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Mode:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.paymentOption}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>{OrderBasic?.paymentOption === 'Interac e-Transfer' ? 'Transaction No:' : 'Payment Id:'}</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.paymentOption === 'Interac e-Transfer' ? OrderBasic?.transactionNum : OrderBasic?.paymentIntentId}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Shipping Cost:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.shippingCost}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Service:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{OrderBasic?.shippingService}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>






                <Container>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            aria-label="full width tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab
                                label="Ordered Items"
                                sx={{
                                    bgcolor: value === 0 && '#00bca4',
                                    border: value === 0 && '1px solid silver',
                                    borderRadius: value === 0 && '4px',
                                    textTransform: 'none'
                                }}
                                {...a11yProps(0)} />
                                <Tab
                                label="Label"
                                sx={{
                                    bgcolor: value === 1 && '#00bca4',
                                    border: value === 1 && '1px solid silver',
                                    borderRadius: value === 1 && '4px',
                                    textTransform: 'none'
                                }}
                                {...a11yProps(1)} />
                            <Tab
                                label="Invoice"
                                sx={{
                                    bgcolor: value === 2 && '#00bca4',
                                    border: value === 2 && '1px solid silver',
                                    borderRadius: value === 2 && '4px',
                                    textTransform: 'none'
                                }}
                                {...a11yProps(2)} />
                                     <Tab
                                label="Packing List"
                                sx={{
                                    bgcolor: value === 3 && '#00bca4',
                                    border: value === 3 && '1px solid silver',
                                    borderRadius: value === 3 && 3,
                                    textTransform: 'none'
                                }}
                                {...a11yProps(3)}
                            />
                        </Tabs>
                    </Box>

                    {/* Orders items */}
                    <TabPanel value={value} index={0} >
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>#</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                Product
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                Name
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Quantity</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Price</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            OrdersList?.map((i, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            <img style={{ width: 80, height: 80 }} src={`${BaseImageAPI}${i.mainImage}`} alt='Image' />
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productName}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.orderedQty}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productPrice}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Total Quantity (Nos):</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} >
                                                {
                                                    OrderBasic?.totalPieces
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Toatl Weight (LB):</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} >
                                                {
                                                    OrderBasic?.totalWeight
                                                }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Taxes ($):</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} >
                                                {
                                                    OrderBasic?.taxCost
                                                }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Shipping Cost ($):</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} >
                                                {
                                                    OrderBasic?.shippingCost
                                                }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Total Order Cost ($):</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {
                                                    OrderBasic?.totalCost
                                                }
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                    <Box mb={2}>
                        <TableContainer >
                            <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Order Number</TableCell>
                                        {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Invoice Number</TableCell> */}
                                        <TableCell align="center">LABEL PRINT</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>

                                        <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                            {OrderBasic?.orderTime}
                                        </TableCell>

                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                            {OrderBasic?.userOrderId}
                                        </TableCell>

                                        {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                            {OrderBasic?.orderNumber}
                                        </TableCell> */}

                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                            <Button onClick={handlegenerateLabel} variant='outlined' color='warning' sx={{ textTransform: 'none', height: 25 }}>
                                        <PrintIcon sx={{ verticalAlign: 'middle', mr: 1 }} fontSize='small' /> Print</Button>
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </TabPanel>

                    <TabPanel value={value} index={2}>
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Order Number</TableCell>
                                            {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Invoice Number</TableCell> */}
                                            <TableCell align="center">PRINT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                {OrderBasic?.orderTime}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {OrderBasic?.userOrderId}
                                            </TableCell>

                                            {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {OrderBasic?.orderNumber}
                                            </TableCell> */}

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <Button onClick={handlegenerateInvoice} variant='outlined' color='warning' sx={{ textTransform: 'none', height: 25 }}>
                                            <PrintIcon sx={{ verticalAlign: 'middle', mr: 1 }} fontSize='small' /> Print</Button>
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Order Number</TableCell>
                                            {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Invoice Number</TableCell> */}
                                            <TableCell align="center">PRINT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                {OrderBasic?.orderTime}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {OrderBasic?.userOrderId}
                                            </TableCell>

                                            {/* <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {OrderBasic?.orderNumber}
                                            </TableCell> */}

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <Button onClick={handlegeneratePackingList} variant='outlined' color='warning' sx={{ textTransform: 'none', height: 25 }}>
                                            <PrintIcon sx={{ verticalAlign: 'middle', mr: 1 }} fontSize='small' /> Print</Button>
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>
                    
                </Container>

                <Box p={1} display='flex' justifyContent='end'>
                    <IconButton onClick={() => navigate(-1)} sx={{ width: 100, height: 40, border: '3px solid black', borderRadius: 1 }}>
                        <Button sx={{ color: '#000000', fontWeight: 600, textTransform: 'none' }}><FastRewindOutlinedIcon sx={{ verticalAlign: 'middle' }} /> Back</Button>
                    </IconButton>
                </Box>
            </Box>

            <ToastContainer />

        </Box>
    )
}
