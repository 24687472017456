import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material'
import { CategoryImageAPI, delete_main_category, delete_purchase} from '../../Services/APIService';
import axios from 'axios';
import { useState } from 'react';

export default function DeletePurchaseDialog({setOpenDialog,getPurchase ,  openDialog, i, setMessage, setOpen, setStatus, setColor,}) {


   const handleClose = () => {
    setOpenDialog(false);
    getPurchase();
    };

    const handleDelete = () => {
            const sendData = new FormData();
            sendData.append('purchaseId', i.purchaseId);
            sendData.append('paidAmount', i.paidAmount);
            sendData.append('colorQty', JSON.stringify(i.colorQty));
            axios({
                method: "POST",
                url: delete_purchase,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                }
            }).catch(err => {
                console.log(err)
            });
    }


        
  return (
    <div>
        <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title" sx={{fontSize:17}}>
        {"ARE YOU SURE THAT YOU WANT TO DELETE THIS PURCHASE PERMANENTLY ?"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Box display='flex' flexDirection='column' gap={2}> 
        <Typography>Bill No : <Typography component='span' color='#333' fontWeight={600}>{i.billNo}</Typography></Typography>
        <Typography>Supplier : <Typography component='span' color='#333' fontWeight={600}> {i.supplierName}</Typography>  </Typography>
        </Box>                                             
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDelete}>YES</Button>
        <Button onClick={handleClose} autoFocus>
        NO
        </Button>
        </DialogActions>
        </Dialog>
    </div>
  )
}
